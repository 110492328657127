import React, { useRef, useState, useContext, useEffect } from "react";
import AddAreasModal from "../AddAreasModal";
import { AuthContext } from "../../context/AuthContext";
import { getAreas, queryAreas } from "../Functions";
import { onSnapshot, doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";
import "./Areas.css";
import DeleteArea from "./DeleteArea";
import { Grid } from "lucide-react";

const RbAreas = () => {
  const AddAreasRef = useRef(null);
  const { currentUser } = useContext(AuthContext);
  const [areas, setAreas] = useState([]);
  const idBusiness = sessionStorage.getItem("businessId");
  const [isDeleteAreaModalOpen, setIsDeleteAreaModalOpen] = useState(false);
  const [areaToDelete, setAreaToDelete] = useState(null);

  const handleOpenModalAreas = () => {
    AddAreasRef.current.handleOpen();
  };

  const handleDeleteAreaClick = (event, area) => {
    event.stopPropagation();
    setAreaToDelete(area);
    setIsDeleteAreaModalOpen(true);
  };

  const handleCloseDeleteAreaModal = () => {
    setIsDeleteAreaModalOpen(false);
    setAreaToDelete(null);
  };

  const handleDeleteArea = () => {
    if (areaToDelete) {
      deleteArea(idBusiness, areaToDelete);
    }
  };

  async function deleteArea(idBusiness, area) {
    try {
      await deleteDoc(doc(db, "business", idBusiness, "areas", area));
    } catch (error) {
      console.error("Error deleting area:", error);
    }
  };

  useEffect(() => {
    if (idBusiness && currentUser && currentUser.email) {
      const q = queryAreas(idBusiness);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = getAreas(querySnapshot);
        setAreas(items);
      });
      return unsubscribe;
    }
  }, [currentUser, idBusiness]);

  return (
    <div className="form-step">
      <h2 className="form-title"><Grid className="header-icon" />  
       Áreas de tu vida</h2>
      <div className="intro-text-container">
        <p className="intro-text">
          Define las diferentes áreas de tu vida (como salud, trabajo, familia, hobbies) 
          para organizar mejor tus actividades en el calendario.
        </p>
        <p className="intro-text">
          Personaliza cada área con un color e ícono para identificar 
          fácilmente tus eventos cuando los veas en el calendario.
        </p>
      </div>
      <div className="project-list-container">
        {areas.map((area) => (
          <div key={area.id} className="project-item">
            <div className="project-content">
              <span className="project-icon">{area.icon}</span>
              <span className="project-name">{area.name}</span>
              <span
                className="project-color-dot"
                style={{ backgroundColor: area.color }}
              />
            </div>
            <button
              className="delete-button"
              onClick={(e) => handleDeleteAreaClick(e, area.name)}
              aria-label={`Eliminar ${area.name}`}
            >
              ✕
            </button>
            <div className="project-tooltip">
              <div className="tooltip-arrow"></div>
              <h4>{area.name}</h4>
              <p><strong>Objetivo:</strong> {area.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div>
        <button
          className="formButton"
          onClick={handleOpenModalAreas}
        >
          Agregar Área
        </button>
      </div>
      <AddAreasModal
        ref={AddAreasRef}
        modalDescription="Las áreas representan la categorización de tu proyecto."
        modalTitle="Agregar áreas"
        id={idBusiness}
      />
      <DeleteArea
        isOpen={isDeleteAreaModalOpen}
        onClose={handleCloseDeleteAreaModal}
        idBusiness={idBusiness}
        area={areaToDelete}
        onDelete={handleDeleteArea}
      />
    </div>
  );
};

export default RbAreas;