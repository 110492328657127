import React from "react";
import Modal from "react-modal";

function InfoModal({ isOpen, message, onClose, title, context, fechaInicio, fechaTermino, emailBusiness, id, businessName, horaInicio, horaTermino }) {
 
  return (
    <Modal
      overlayClassName={"modal-overlay"}
      className={"modal-content"}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <h2 className="titleModal">Info</h2>
      <div className="descriptionModal">
        <p>{message}</p>

      </div>

      <div className="row">
        <button className="btnCloseModal" onClick={onClose}>
          Cerrar
        </button>
      </div>
    </Modal>
  );
}

export default InfoModal;