import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDrop } from "react-dnd";
import {
  loadData,
  saveData,
  uploadFile,
  createNotification,
} from "../Functions.js";
import "./WorkSpaces.css";
import Menu from "./Menu";
import TextBox from "./TextBox";
import ImageBox from "./ImageBox";
import VideoBox from "./VideoBox"; // Importa el nuevo componente
import FileBox from "./FileBox";
import CheckList from "./CheckList";
import TrashCan from "./TrashCan";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faArrowLeft,
  faCommentDots,
  faPlusCircle,
  faVideo
} from "@fortawesome/free-solid-svg-icons";
import ToWorkSpace from "./ToWorkSpaces.jsx";
import { sendMail } from "../SendMail"; // Importa la función sendMail
import { AuthContext } from "../../context/AuthContext.js";
import ChatWorkspace from "../ChatWorkspace/ChatWorkspace";

const ItemType = "ITEM";

function WorkSpaces({ isPublic, ticketId, businessEmail, location }) {
  const [items, setItems] = useState([]);
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false); // Nuevo estado para rastrear cambios
  const workspaceRef = useRef(null);
  const navigate = useNavigate();
  const [isPasting, setIsPasting] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const params = useParams();

  // Obtener datos del ticket
  const ticketData = location?.state;
  
  // Determinar si el usuario tiene permisos de edición
  const hasEditPermissions = React.useMemo(() => {
    if (!currentUser?.email || !ticketData) return false;
    
    // Usuario está en la lista de invitados
    const isInvited = ticketData.to?.includes(currentUser.email);
    // Usuario es el creador
    const isCreator = ticketData.from === currentUser.email;
    // Usuario es el propietario explícito
    const isOwner = ticketData.isOwner;

    return isInvited || isCreator || isOwner;
  }, [currentUser?.email, ticketData]);

  const {
    title,
    businessName,
    priority,
    vencimiento,
    creacion,
    department,
    status,
    emailClient,
    emailBusiness: workspaceEmailBusiness,
    descripcion,
    context,
    skill,
    plan,
    creator,
    uid,
    to = [],
    from,
    horaCreacion,
    enlaceMeet,
  } = ticketData || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Modificar loadData para manejar acceso público
        const data = await loadData(businessEmail, ticketId, isPublic);
        if (data && data.items) {
          setItems(data.items);
        } else {
          setItems([]);
        }
      } catch (error) {
        console.error("Error loading workspace:", error);
        // Manejar error - mostrar mensaje al usuario
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [businessEmail, ticketId, isPublic]);

  const notifyUsers = () => {
    to.forEach(async (userEmail) => {
      if (userEmail !== currentUser.email) {
        // Crear mensaje de notificación
        const message = `El workspace: ${title} ha sido actualizado por ${currentUser.displayName}.`;

        // Filtrar datos undefined
        const workspaceData = {
          title,
          businessName,
          priority,
          vencimiento,
          creacion,
          department,
          status,
          emailClient,
          emailBusiness: workspaceEmailBusiness,
          descripcion,
          context,
          skill,
          plan,
          creator,
          id: ticketId,
          uid,
          to,
          from,
          horaCreacion,
        };

        // Eliminar campos undefined
        const filteredWorkspaceData = Object.fromEntries(
          Object.entries(workspaceData).filter(([_, v]) => v !== undefined)
        );

        // Crear notificación en Firestore
        await createNotification(
          userEmail,
          "Workspaces",
          message,
          filteredWorkspaceData,
          "workspace",
          department,
          businessEmail
        );
      }
    });
  };

  const handleSave = async () => {
    if (!hasEditPermissions) return;
    
    setLoading(true);
    try {
      await saveData(businessEmail, ticketId, items);
      alert("Datos guardados exitosamente");
      notifyUsers();
      setHasChanges(false);
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Error al guardar los datos");
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateAndSave = async () => {
    if (hasEditPermissions) {  // Siempre intentar guardar si tiene permisos
      setLoading(true);
      try {
        await saveData(businessEmail, ticketId, items);
        if (hasChanges) {  // Solo notificar si hubo cambios
          notifyUsers();
          alert("Datos guardados exitosamente");
        }
        setHasChanges(false);
      } catch (error) {
        console.error("Error saving data:", error);
        alert("Error al guardar los datos");
        return; // No navegar si hay error al guardar
      } finally {
        setLoading(false);
      }
    }
    navigate("/gestionarTickets");
  };

  const handleRightClick = (e) => {
    e.preventDefault();
    setMenuPosition({ x: e.clientX, y: e.clientY });
    setMenuVisible(true);
  };

  const handleAddElement = (e) => {
    e.preventDefault();
    setMenuPosition({ x: e.clientX - 300, y: e.clientY - 150});
    setMenuVisible(true);
  };

  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.key === "m") {
      setMenuPosition({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
      setMenuVisible(true);
    }
  };

  const handleAddItem = (type) => {
    const newItem = {
      id: `item-${items.length}-${Date.now()}`, // Use a unique key to avoid conflicts
      type,
      content: type === "checklist" ? [] : "",
      position: menuPosition,
      width: 200,
      height: 100,
      color: "#ffffff",
    };
    setItems((prevItems) => [...prevItems, newItem]);
    setMenuVisible(false);
    setHasChanges(true); // Marcar cambios
  };

  const handleMouseDown = (e) => {
    if (e.button === 0 && e.target === workspaceRef.current) {
      const startX = e.clientX - translate.x;
      const startY = e.clientY - translate.y;

      const handleMouseMove = (e) => {
        setTranslate({
          x: e.clientX - startX,
          y: e.clientY - startY,
        });
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
  };

  const [, drop] = useDrop({
    accept: ItemType,
    drop: (item, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      const newItems = Array.from(items);
      const movedItem = newItems[item.index];

      if (movedItem) {
        movedItem.position = {
          x: Math.round(movedItem.position.x + delta.x),
          y: Math.round(movedItem.position.y + delta.y),
        };
        setItems(newItems);
        setHasChanges(true); // Marcar cambios
      }
    },
  });

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handlePasteEvent = (e) => {
      handlePaste(e);
    };

    window.addEventListener("paste", handlePasteEvent);

    return () => {
      window.removeEventListener("paste", handlePasteEvent);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      handleSave();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [items]);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);

    for (const file of files) {
      const fileType = file.type;

      if (fileType.startsWith("image/")) {
        const filePath = `images/${file.name}`;
        const imageUrl = await uploadFile(file, filePath);
        const newItem = {
          id: `item-${items.length}-${Date.now()}`, // Use a unique key to avoid conflicts
          type: "image",
          content: imageUrl,
          position: {
            x: e.clientX - workspaceRef.current.getBoundingClientRect().left,
            y: e.clientY - workspaceRef.current.getBoundingClientRect().top,
          },
          width: 200,
          height: 100,
          color: "#ffffff",
        };
        setItems((prevItems) => [...prevItems, newItem]);
        setHasChanges(true); // Marcar cambios
      } else {
        const filePath = `files/${file.name}`;
        const fileUrl = await uploadFile(file, filePath);
        const newItem = {
          id: `item-${items.length}-${Date.now()}`, // Use a unique key to avoid conflicts
          type: "file",
          content: {
            name: file.name,
            type: file.type,
            content: fileUrl,
          },
          position: {
            x: e.clientX - workspaceRef.current.getBoundingClientRect().left,
            y: e.clientY - workspaceRef.current.getBoundingClientRect().top,
          },
          width: 200,
          height: 100,
          color: "#ffffff",
        };
        setItems((prevItems) => [...prevItems, newItem]);
        setHasChanges(true); // Marcar cambios
      }
    }
  };

  const handlePaste = async (e) => {
    e.preventDefault();
    setIsPasting(true);
    const items = Array.from(e.clipboardData.items);

    for (const item of items) {
      if (item.kind === "file") {
        const file = item.getAsFile();
        if (file.type.startsWith("image/")) {
          const filePath = `images/${file.name}`;
          const imageUrl = await uploadFile(file, filePath);
          const newItem = {
            id: `item-${items.length}-${Date.now()}`, // Use a unique key to avoid conflicts
            type: "image",
            content: imageUrl,
            position: {
              x: window.innerWidth / 2 - 100,
              y: window.innerHeight / 2 - 50,
            },
            width: 200,
            height: 100,
            color: "#ffffff",
          };
          setItems((prevItems) => [...prevItems, newItem]);
          setHasChanges(true); // Marcar cambios
        } else {
          const filePath = `files/${file.name}`;
          const fileUrl = await uploadFile(file, filePath);
          const newItem = {
            id: `item-${items.length}-${Date.now()}`, // Use a unique key to avoid conflicts
            type: "file",
            content: {
              name: file.name,
              type: file.type,
              content: fileUrl,
            },
            position: {
              x: window.innerWidth / 2 - 100,
              y: window.innerHeight / 2 - 50,
            },
            width: 200,
            height: 100,
            color: "#ffffff",
          };
          setItems((prevItems) => [...prevItems, newItem]);
          setHasChanges(true); // Marcar cambios
        }
      }
    }
    setIsPasting(false);
  };

  // Función para manejar el clic en el botón de reunión
  const handleMeetClick = () => {
    if (enlaceMeet) {
      window.open(enlaceMeet, '_blank');
    }
  };

  return (
    <div
      className="workspace-container_ws"
      ref={workspaceRef}
      onMouseDown={handleMouseDown}
      onContextMenu={handleRightClick}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <>
          {/* Mostrar controles de navegación si tiene permisos */}
          {hasEditPermissions && (
            <>
              <div className="navigation-arrow" onClick={handleNavigateAndSave}>
                <FontAwesomeIcon icon={faArrowLeft} size="2x" />
              </div>
              <ToWorkSpace to={to} />
            </>
          )}
          <div className="workspace-title_ws">{title}</div>
          <div
            className="workspace_ws"
            ref={drop}
            style={{
              transform: `scale(${scale}) translate(${translate.x}px, ${translate.y}px)`,
            }}
          >
            {items &&
              items.map((item, index) => {
                const uniqueKey = `${item.id}-${index}-${Date.now()}`;
                switch (item.type) {
                  case "text":
                    return (
                      <TextBox
                        key={uniqueKey}
                        item={item}
                        index={index}
                        setItems={setItems}
                        items={items}
                        isPublic={!hasEditPermissions}
                        setHasChanges={setHasChanges}
                      />
                    );
                  case "image":
                    return (
                      <ImageBox
                        key={uniqueKey}
                        item={item}
                        index={index}
                        setItems={setItems}
                        items={items}
                        isPublic={!hasEditPermissions}
                        setHasChanges={setHasChanges}
                      />
                    );
                  case "video":
                    return (
                      <VideoBox
                        key={uniqueKey}
                        item={item}
                        index={index}
                        setItems={setItems}
                        items={items}
                        isPublic={!hasEditPermissions}
                        setHasChanges={setHasChanges}
                      />
                    );
                  case "file":
                    return (
                      <FileBox
                        key={uniqueKey}
                        item={item}
                        index={index}
                        setItems={setItems}
                        items={items}
                        isPublic={!hasEditPermissions}
                        setHasChanges={setHasChanges}
                      />
                    );
                  case "checklist":
                    return (
                      <CheckList
                        key={uniqueKey}
                        item={item}
                        index={index}
                        setItems={setItems}
                        items={items}
                        isPublic={!hasEditPermissions}
                        setHasChanges={setHasChanges}
                      />
                    );
                  default:
                    return null;
                }
              })}
          </div>
          {/* Mostrar controles de edición si tiene permisos */}
          {hasEditPermissions && (
            <>
              <TrashCan setItems={setItems} items={items} setHasChanges={setHasChanges} />
              <button onClick={handleSave} className="save-button">
                <FontAwesomeIcon icon={faSave} size="2x" />
              </button>
              <button onClick={handleAddElement} className="add-element-button">
                <FontAwesomeIcon icon={faPlusCircle} size="2x" />
              </button>
            </>
          )}
          {/* Menú y mensaje de pegado si tiene permisos */}
          {hasEditPermissions && menuVisible && (
            <Menu
              position={menuPosition}
              onAddItem={handleAddItem}
              onClose={() => setMenuVisible(false)}
            />
          )}
          {hasEditPermissions && isPasting && (
            <div className="pasting-message">
              <span>Pegando...</span>
            </div>
          )}
          {/* Botón de Meet siempre visible si hay enlace */}
          {enlaceMeet && (
            <button onClick={handleMeetClick} className="meet-button">
              <FontAwesomeIcon icon={faVideo} size="2x" />
            </button>
          )}
          {/* Chat visible solo para usuarios con permisos */}
          {hasEditPermissions && (
            <>
              <div
                className="floating-chat-button"
                onClick={() => setIsChatOpen(!isChatOpen)}
              >
                <FontAwesomeIcon icon={faCommentDots} size="2x" />
              </div>
              {isChatOpen && businessEmail && ticketId && (
                <ChatWorkspace
                  emailBusiness={businessEmail}
                  ticketId={ticketId}
                  title={title}
                  users={to}
                  department={department}
                  onClose={() => setIsChatOpen(false)}
                  workspaceData={Object.fromEntries(
                    Object.entries({
                      title,
                      businessName,
                      priority,
                      vencimiento,
                      creacion,
                      department,
                      status,
                      emailClient,
                      emailBusiness: workspaceEmailBusiness,
                      descripcion,
                      context,
                      skill,
                      plan,
                      creator,
                      id: ticketId,
                      uid,
                      to,
                      from,
                      horaCreacion,
                    }).filter(([_, v]) => v !== undefined)
                  )}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default WorkSpaces;
