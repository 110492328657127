import {
  collection,
  doc,
  query,
  where,
  or,
  and,
  getDoc,
  getDocs,
  writeBatch,
  collectionGroup,
  deleteDoc,
  setDoc,
  getFirestore,
  updateDoc,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import { db, storage, auth } from "./firebase";
import { updateGoogleCalendarEvent } from "./calendario/FunctionsCalendar";
import { orderBy, limit, startAfter, addDoc } from "firebase/firestore";
import {
  getDownloadURL,
  uploadBytes,
  ref,
  deleteObject,
} from "firebase/storage";
import moment from "moment";
import "moment-timezone";
import { getFecha, getHora } from "./IA/Gpt";
import { toast } from "react-toastify";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { deleteField } from "firebase/firestore";
import axios from "axios";
const navigate = { useNavigate };
export async function getTicketContext(descripcion) {
  const { Configuration, OpenAIApi } = require("openai");
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const response = await openai.createCompletion({
    model: "text-davinci-003",
    prompt:
      "del siguente texto:" +
      descripcion +
      "selecciona a que departamento va dirigido en una sola palabra: finanzas, RRHH, informática, contabilidad, tesorería, administracion, legal, ventas, compras, produccion, calidad, logistica",
    temperature: 0,
    max_tokens: 10,
    top_p: 1.0,
    frequency_penalty: 0.0,
    presence_penalty: 0.0,
  });
  return response.data.choices[0].text;
}

export async function getTicketResume(descripcion) {
  const { Configuration, OpenAIApi } = require("openai");
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const response = await openai.createCompletion({
    model: "gpt-3.5-turbo",
    prompt: "cuál sería la solución a la siguiente solicitud:" + descripcion,
    temperature: 0.9,
    max_tokens: 50,
    top_p: 1.0,
    frequency_penalty: 0.0,
    presence_penalty: 0.0,
  });
  return response.data.choices[0].text;
}

export function getImageUrl(place) {
  return "https://i.imgur.com/" + place.imageId + "l.jpg";
}

export function getImageUrl2(place) {
  return console.log(place);
}

export const renewAccessToken = async () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/calendar");
  provider.addScope("https://www.googleapis.com/auth/calendar.events");

  try {
    const result = await signInWithPopup(auth, provider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    sessionStorage.setItem("accessToken", token);
    return token;
  } catch (error) {
    console.error("Error signing in:", error);
    throw new Error("Unable to renew access token");
  }
};

export function daysBetween(fechaInicio, fechaFin) {
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = fechaFin.getTime() - fechaInicio.getTime();
  const diffInDays = Math.round(diffInTime / oneDay) + 1;
  return diffInDays;
}

export function convertDateFormat(dateStr) {
  // separar la fecha en partes usando una expresión regular
  const parts = dateStr.match(/(\d{2})-(\d{2})-(\d{4})/);
  // reordenar las partes y unirlas de nuevo
  const newDateStr = `${parts[2]}-${parts[1]}-${parts[3]}`;
  return newDateStr;
}

export function convertChatDate(hrs) {
  const timestamp = hrs.seconds * 1000 + hrs.nanoseconds / 1000000; // convertir a milisegundos
  const date = new Date(timestamp); // objeto de fecha

  const day = date.getDate(); // día del mes (1-31)
  const month = date.getMonth() + 1; // mes (0-11, sumar 1 para ajustar el índice)
  const year = date.getFullYear(); // año (4 dígitos)

  const hours = date.getHours(); // hora (0-23)
  const minutes = date.getMinutes(); // minutos (0-59)

  // Formato fecha y hora
  const formattedDateTime = ` ${hours}:${minutes} del ${day}-${month}-${year}`;

  return formattedDateTime;
}

export function getQuery(userType, department, idUser, status) {
  if (userType === "admin") {
    const q = query(
      collection(db, "tickets"),
      where("status", "==", status),
      where("department", "==", department),
      orderBy("today", "asc"),
      limit(4)
    );
    return q;
  } else if (userType === "user") {
    const q = query(
      collection(db, "tickets"),
      where("status", "==", status),
      where("department", "==", department),
      where("email", "==", idUser),
      orderBy("today", "asc"),
      limit(4)
    );
    return q;
    console.log(q);
  }
}

export function queryMessages(emailBusiness, idTicket) {
  const q = query(
    collection(db, "business", emailBusiness, "tickets", idTicket, "messages"),
    orderBy("date", "asc")
  );
  return q;
}

export function queryAreas(emailBusiness) {
  const q = query(collection(db, "business", emailBusiness, "areas"));
  return q;
}

export function queryUserArea(emailBusiness) {
  const q = query(collection(db, "business", emailBusiness, "areas"));
  return q;
}

export function getUserArea(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

export function queryAgents(idBusiness) {
  const q = query(collection(db, "business", idBusiness, "agents"));
  return q;
}

export function queryCompetencias(emailBusiness, agentArea) {
  const q = query(
    collection(
      db,
      "business",
      emailBusiness,
      "areas",
      agentArea,
      "competencias"
    )
  );
  return q;
}

export function queryTop10Agents(emailBusiness) {
  return query(
    collection(db, `business/${emailBusiness}/agents`),
    orderBy("puntos", "desc"), // Ordena los documentos por el campo 'puntos' de mayor a menor
    limit(10) // Limita los resultados a los 10 primeros documentos
  );
}

export function queryCompetenciasTop10Agents(emailBusiness, agentArea) {
  const q = query(
    collection(
      db,
      "business",
      emailBusiness,
      "areas",
      agentArea,
      "competencias"
    )
  );
  return q;
}

export function getCompetencias(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

// Función para obtener las áreas asignadas a un usuario
/* export async function getAssignedAreas(userEmail) {
  const areasRef = collection(db, "users", userEmail, "department");
  const snapshot = await getDocs(areasRef);
  return snapshot.docs.map((doc) => doc.id); // Obtiene los IDs de los subdocumentos que son los nombres de las áreas
}
 */

export function queryCompetenci(businessEmail, area) {
  const q = query(
    collection(db, "business", businessEmail, "areas", area, "compentencias")
  );
  return q;
}

// Función para obtener las competencias de una área específica
export async function getCompetenciasForArea(emailBusiness, area) {
  try {
    // Referencia a la colección de competencias para una área específica
    const competenciasRef = collection(
      db,
      "business",
      emailBusiness,
      "areas",
      area,
      "competencias"
    );
    // Obtener los documentos (competencias) de la colección
    const snapshot = await getDocs(competenciasRef);
    // Mapear los documentos a un array de objetos con los datos de las competencias
    const competencias = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return competencias;
  } catch (error) {
    // Si hay un error, imprimirlo en consola y retornar un array vacío o manejar el error como prefieras
    console.error("Error al obtener competencias para el área:", area, error);
    return [];
  }
}

export function queryGetCompentenciasDelAgente(selectedAgent, selectedEmpresa) {
  const q = query(
    collection(
      db,
      "users",
      selectedAgent,
      "businessPartner",
      selectedEmpresa,
      "competencias"
    )
  );
  return q;
}

/* export function queryAgentCompetencias(selectedEmpresa, area, selectedAgent) {
  const q = query(
    collection(
      db,
      "clients",
      selectedEmpresa,
      area,
      selectedAgent,
      "competencias"
    )
  );
  return q;
} */

export function queryAgentCompetencias(
  selectedEmpresa,
  businessEmail,
  selectedAgent
) {
  const q = query(
    collection(
      db,
      "business",
      businessEmail,
      "agents",
      selectedAgent,
      "businessPartner",
      selectedEmpresa,
      "competencias"
    )
  );
  return q;
}

export function getAgentCompetencias(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

export function getAgents(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

export function queryClients(idBusiness) {
  const q = query(collection(db, "business", idBusiness, "clients"));
  return q;
}

export function getClients(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

export function queryInvitacionesAceptadas(clientEmail) {
  const q = query(
    collection(db, "clients", clientEmail, "invitaciones"),
    where("status", "==", "aceptada")
  );
  return q;
}

export function getInvitacionesPendientes(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

export function queryInvitacionesPendientes(clientEmail) {
  const q = query(
    collection(db, "clients", clientEmail, "invitaciones"),
    where("status", "==", "pendiente")
  );
  return q;
}

export function getInvitacionesAceptadas(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

export function getAreas(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

export function querySkills(idBusiness, area) {
  const q = query(
    collection(db, "business", idBusiness, "areas", area, "competencias")
  );
  return q;
}

export function getSkills(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

/* export function queryBusinessPartner(emailUser) {
  const q = query(collection(db, "users", emailUser, "businessPartner"));
  return q;
} */

export function queryBusinessPartner(activeAgent, businessEmail) {
  const q = query(
    collection(
      db,
      "business",
      businessEmail,
      "agents",
      activeAgent,
      "businessPartner"
    )
  );
  return q;
}

/* export function queryAreasAgent(emailUser) {
  const q = query(collection(db, "users", emailUser, "department"));
  return q;
}
 */
export function queryAreasAgent(activeAgent, idBusiness) {
  const q = query(
    collection(db, "business", idBusiness, "agents", activeAgent, "areas")
  );
  return q;
}

export function getAreaAgent(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id }); // Aseguramos de incluir todos los datos del documento y su ID.
  });
  return items;
}

export function getBusinessPartner(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });
  return items;
}

export function getQueryAfter(
  userType,
  department,
  idUser,
  status,
  firstVisible
) {
  if (userType === "admin") {
    const q = query(
      collection(db, "tickets"),
      where("status", "==", status),
      where("department", "==", department),
      orderBy("today", "desc"),
      limit(4),
      startAfter(firstVisible)
    );
    return q;
  } else if (userType === "user") {
    const q = query(
      collection(db, "tickets"),
      where("status", "==", status),
      where("department", "==", department),
      where("email", "==", idUser),
      orderBy("today", "desc"),
      limit(4),
      startAfter(firstVisible)
    );
    return q;
  }
}

export function getQueryNext(
  userType,
  department,
  idUser,
  status,
  lastVisible
) {
  if (userType === "admin") {
    const q = query(
      collection(db, "tickets"),
      where("status", "==", status),
      where("department", "==", department),
      orderBy("today", "asc"),
      limit(4),
      startAfter(lastVisible)
    );
    return q;
  } else if (userType === "user") {
    const q = query(
      collection(db, "tickets"),
      where("status", "==", status),
      where("department", "==", department),
      where("email", "==", idUser),
      orderBy("today", "asc"),
      limit(4),
      startAfter(lastVisible)
    );
    return q;
  }
}

export function getTickets(querySnapshot) {
  const items_aux = [];
  const items = [];
  querySnapshot.forEach((doc) => {
    items_aux.push({ ...doc.data(), id: doc.id });
  });
  items_aux.forEach((item) => {
    const fechaInicio = new Date();
    const fechaFin = new Date(convertDateFormat(item.vencimiento));
    daysBetween(fechaInicio, fechaFin);
    const diff = daysBetween(fechaInicio, fechaFin);
    items.push({ ...item, restante: diff });
  });
  return items;
}

export function getMessages(querySnapshot) {
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ ...doc.data(), id: doc.id });
  });

  return items;
}

export function sendWhatsapp() {
  var botId = "105088769230115";
  var phoneNbr = "56976045123";
  var bearerToken =
    "EAANFMsGcZAroBAF4chqYAwMddxMnmJOZB3srENHxEIESJLXaMh4zJbut0tgaPKszNxB41R78iry73ZBUGxDF3hO8ABmOAKW6iHWkOK4fnb9qFyen7e8KA6MWcOZBnb5SCkdZCIsRVnrsOfZCBM4iEGCa2xZC3TnSrXmtGPexXAo6Wyj6oNbwzDAfpGZAHMnDGov6GRA8WzRMBQZDZD";
  var url = "https://graph.facebook.com/v15.0/" + botId + "/messages";
  var data = {
    messaging_product: "whatsapp",
    to: phoneNbr,
    type: "template",
    template: {
      name: "hello_world",
      language: { code: "en_US" },
    },
  };
  var postReq = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + bearerToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    json: true,
  };
  fetch(url, postReq)
    .then((data) => {
      return data.json();
    })
    .then((res) => {
      console.log(res);
    })
    .catch((error) => console.log(error));
}

export function sendWhatsAppCustom(botId, number, message) {
  var bearerToken =
    "EAANFMsGcZAroBAF4chqYAwMddxMnmJOZB3srENHxEIESJLXaMh4zJbut0tgaPKszNxB41R78iry73ZBUGxDF3hO8ABmOAKW6iHWkOK4fnb9qFyen7e8KA6MWcOZBnb5SCkdZCIsRVnrsOfZCBM4iEGCa2xZC3TnSrXmtGPexXAo6Wyj6oNbwzDAfpGZAHMnDGov6GRA8WzRMBQZDZD";
  var url = "https://graph.facebook.com/v15.0/" + botId + "/messages";
  var data = {
    messaging_product: "whatsapp",
    recipient_type: "individual",
    to: number,
    type: "text",
    text: {
      preview_url: false,
      body: message,
    },
  };

  var postReq = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + bearerToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    json: true,
  };
  fetch(url, postReq)
    .then((data) => {
      return data.json();
    })
    .then((res) => {
      console.log(res);
    })
    .catch((error) => console.log(error));
}

// funciones para devolver tickets en base a condiciones preestablecidas:

async function filterByAgentId(documentsSnapshot, emailAgent) {
  const filteredDocs = [];
  documentsSnapshot.forEach((doc) => {
    const data = doc.data();
    const to = data.to;
    if (to && to.some((obj) => obj.agenteId === emailAgent)) {
      filteredDocs.push(doc);
    }
  });
  return filteredDocs;
}

export async function getFilteredTickets(privileges, status, emailAgent, db) {
  if (privileges === "agent") {
    const q = query(
      collection(db, "tickets"),
      where("status", "==", status),
      orderBy("today", "desc")
    );

    const querySnapshot = await getDocs(q);
    const filteredDocs = await filterByAgentId(querySnapshot, emailAgent);
    return filteredDocs;
  }
}

export function queryGetTickets(
  privileges,
  emailBusiness,
  department,
  status,
  emailAgent
) {
  let q1, q2, q3; // Declaramos 'q1', 'q2', 'q3' en un ámbito superior para acceso en todo el bloque

  if (privileges === "admin") {
    if (department === "General") {
      q1 = query(
        collection(db, "business", emailBusiness, "tickets"),
        and(
          where("emailBusiness", "==", emailBusiness),
          or(
            and(
              where("priority", "in", ["Alta", "Media", "Baja"]),
              where("status", "==", status)
            ),
            and(
              where("status", "==", status),
              where("to", "array-contains", emailAgent)
            )
          )
        ),
        orderBy("creacion", "desc")
      );

      // Condición adicional
      q2 = query(
        collection(db, "business", emailBusiness, "tickets"),
        where("status", "==", status),
        where("from", "==", emailAgent),
        orderBy("creacion", "desc")
      );
    } else {
      q1 = query(
        collection(db, "business", emailBusiness, "tickets"),
        and(
          where("emailBusiness", "==", emailBusiness),
          or(
            and(
              where("department", "==", department),
              where("priority", "in", ["Alta", "Media", "Baja"]),
              where("status", "==", status)
            ),
            and(
              where("department", "==", department),
              where("status", "==", status),
              where("to", "array-contains", emailAgent)
            )
          )
        ),
        orderBy("creacion", "desc")
      );

      // Condición adicional
      q2 = query(
        collection(db, "business", emailBusiness, "tickets"),
        where("status", "==", status),
        where("department", "==", department),
        where("from", "==", emailAgent),
        orderBy("creacion", "desc")
      );
    }

    return { q1, q2, q3: null };
  }

  if (privileges === "supervisor") {
    q1 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "==", status),
      where("to", "array-contains", emailAgent),
      orderBy("creacion", "desc")
    );

    q2 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "==", status),
      where("emailClient", "==", emailAgent),
      orderBy("creacion", "desc")
    );

    q3 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "==", status),
      where("emailBusiness", "==", emailBusiness),
      where("department", "==", department),
      orderBy("creacion", "desc")
    );

    return { q1, q2, q3 };
  }

  if (privileges === "agent" || privileges === "cliente") {
    if (department === "General") {
      // Consulta para cuando el departamento es "General"
      q1 = query(
        collection(db, "business", emailBusiness, "tickets"),
        where("status", "==", status),
        where("to", "array-contains", emailAgent),
        orderBy("creacion", "desc")
      );

      q2 = query(
        collection(db, "business", emailBusiness, "tickets"),
        where("status", "==", status),
        where("emailClient", "==", emailAgent),
        orderBy("creacion", "desc")
      );

      q3 = query(
        collection(db, "business", emailBusiness, "tickets"),
        where("status", "==", status),
        where("department", "==", department),
        where("from", "==", emailAgent),
        orderBy("creacion", "desc")
      );
    } else {
      // Consulta para cuando el departamento no es "General"
      q1 = query(
        collection(db, "business", emailBusiness, "tickets"),
        where("status", "==", status),
        where("department", "==", department),
        where("to", "array-contains", emailAgent),
        orderBy("creacion", "desc")
      );

      q2 = query(
        collection(db, "business", emailBusiness, "tickets"),
        where("status", "==", status),
        where("department", "==", department),
        where("emailClient", "==", emailAgent),
        orderBy("creacion", "desc")
      );
      q3 = query(
        collection(db, "business", emailBusiness, "tickets"),
        where("status", "==", status),
        where("department", "==", department),
        where("from", "==", emailAgent),
        orderBy("creacion", "desc")
      );
    }

    return { q1, q2, q3 };
  }
}

export function queryGetTicketsExtraBoard(
  privileges,
  emailBusiness,
  department,
  boardId,
  emailAgent
) {
  let q;

  if (privileges === "admin") {
    if (department === "General") {
      q = query(
        collection(db, "business", emailBusiness, "tickets"),
        and(
          where("emailBusiness", "==", emailBusiness),
          or(
            and(
              where("priority", "in", ["Alta", "Media", "Baja"]),
              where("status", "==", boardId)
            ),
            and(
              where("status", "==", boardId),
              where("to", "array-contains", emailAgent)
            )
          )
        ),
        orderBy("creacion", "desc")
      );
    } else {
      q = query(
        collection(db, "business", emailBusiness, "tickets"),
        and(
          where("emailBusiness", "==", emailBusiness),
          or(
            and(
              where("department", "==", department),
              where("priority", "in", ["Alta", "Media", "Baja"]),
              where("status", "==", boardId)
            ),
            and(
              where("department", "==", department),
              where("status", "==", boardId),
              where("to", "array-contains", emailAgent)
            )
          )
        ),
        orderBy("creacion", "desc")
      );
    }
    return { q1: q, q2: null, q3: null };
  }

  if (privileges === "supervisor") {
    const q1 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "==", boardId),
      where("to", "array-contains", emailAgent),
      orderBy("creacion", "desc")
    );
    const q2 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "==", boardId),
      where("emailClient", "==", emailAgent),
      orderBy("creacion", "desc")
    );
    const q3 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "==", boardId),
      where("emailBusiness", "==", emailBusiness),
      where("department", "==", department),
      orderBy("creacion", "desc")
    );
    return { q1, q2, q3 };
  }

  if (privileges === "agent" || privileges === "cliente") {
    let q1, q2;
    if (department === "General") {
      q1 = query(
        collection(db, "business", emailBusiness, "tickets"),
        where("status", "==", boardId),
        where("to", "array-contains", emailAgent),
        orderBy("creacion", "desc")
      );
      q2 = query(
        collection(db, "business", emailBusiness, "tickets"),
        where("status", "==", boardId),
        where("emailClient", "==", emailAgent),
        orderBy("creacion", "desc")
      );
    } else {
      q1 = query(
        collection(db, "business", emailBusiness, "tickets"),
        where("status", "==", boardId),
        where("department", "==", department),
        where("to", "array-contains", emailAgent),
        orderBy("creacion", "desc")
      );
      q2 = query(
        collection(db, "business", emailBusiness, "tickets"),
        where("status", "==", boardId),
        where("department", "==", department),
        where("emailClient", "==", emailAgent),
        orderBy("creacion", "desc")
      );
    }
    return { q1, q2, q3: null };
  }
}

export async function createTicket(
  title,
  businessName,
  priority,
  vencimiento,
  creacion,
  department,
  status,
  emailCreator,
  emailBusiness,
  description,
  context,
  skill,
  plan,
  creator,
  to,
  from,
  horaCreacion,
  type
) {
  const refDoc = await addDoc(collection(db, "tickets"), {
    title: title,
    businessName: businessName,
    priority: priority,
    vencimiento: vencimiento,
    creacion: creacion,
    department: department,
    status: status,
    emailClient: emailCreator,
    emailBusiness: emailBusiness,
    descripcion: description,
    context: context,
    skill: skill,
    plan: plan,
    creator: creator,
    to: to,
    from: from,
    horaCreacion: horaCreacion,
    type,
  });
  return refDoc;
}

//función nueva para crear tickets dentro del negocio

export async function createTicketNew(
  title,
  businessName,
  priority,
  vencimiento,
  creacion,
  department,
  status,
  emailCreator,
  emailBusiness,
  description,
  context,
  skill,
  plan,
  creator,
  to,
  from,
  horaCreacion,
  horaInicio,
  horaTermino,
  type,
  color,
  canEdit,
  fechaFin,
  isRoutine = false
) {
  const refDoc = await addDoc(
    collection(db, "business", emailBusiness, "tickets"),
    {
      title: title,
      businessName: businessName,
      priority: priority,
      vencimiento: vencimiento,
      creacion: creacion,
      department: department,
      status: status,
      emailClient: emailCreator,
      emailBusiness: emailBusiness,
      descripcion: description,
      context: context,
      skill: skill,
      plan: plan,
      creator: creator,
      to: to,
      from: from,
      horaCreacion: horaCreacion,
      horaInicio: horaInicio,
      horaTermino: horaTermino,
      type: type,
      color: color,
      canEdit: true,
      fechaFin,
      isRoutine,
    }
  );

  return refDoc;
}

export async function createBoard(businessEmail, boardName, boardColor) {
  const refDoc = await addDoc(
    collection(db, "business", businessEmail, "boards"),
    {
      name: boardName,
      color: boardColor,
      id: new Date().toISOString(),
    }
  );
  return refDoc;
}

export async function getBoards(email) {
  const userRef = doc(db, "users", email);
  const userSnapshot = await getDoc(userRef);

  if (!userSnapshot.exists()) {
    console.log("No such document!");
    return [];
  }

  const userData = userSnapshot.data();
  const businessArray = userData.business || [];

  // Crear promesas para obtener datos de negocios en paralelo
  const businessPromises = businessArray.map(async (business) => {
    const businessDocRef = doc(db, "business", business.id);
    const businessDocSnapshot = await getDoc(businessDocRef);

    if (!businessDocSnapshot.exists()) {
      return null;
    }

    const pendingTicketsCount = await getTicketsCount(
      business.id,
      email,
      business.userType,
      "Pendiente"
    );
    const processTicketsCount = await getTicketsCount(
      business.id,
      email,
      business.userType,
      "Proceso"
    );
    return {
      ...business,
      pendingTickets: pendingTicketsCount,
      processTickets: processTicketsCount,
      ...businessDocSnapshot.data(),
    };
  });

  // Esperar a que todas las promesas se resuelvan
  const fetchedBusinesses = await Promise.all(businessPromises);

  // Filtrar negocios válidos
  const validBusinesses = fetchedBusinesses.filter(Boolean);

  return validBusinesses;
}

async function getTicketsCount(businessId, email, userType, status) {
  const { q1, q2, q3 } = queryGetTickets(
    userType,
    businessId,
    "General",
    status,
    email
  );

  const q1Snapshot = await getDocs(q1);

  const ticketsCount = q1Snapshot.size;

  return ticketsCount;
}

const businessDetailsCache = {};

export async function getBusinessDetails(businessIds) {
  // Filtra los IDs que ya están en la caché
  const idsToFetch = businessIds.filter((id) => !businessDetailsCache[id]);

  if (idsToFetch.length === 0) {
    // Si todos los IDs están en la caché, devuelve los datos directamente
    return businessIds.map((id) => businessDetailsCache[id]);
  }

  try {
    // Crear una consulta que obtenga todos los documentos cuyos ID estén en el array idsToFetch
    const businessQuery = query(
      collection(db, "business"),
      where("__name__", "in", idsToFetch)
    );

    const querySnapshot = await getDocs(businessQuery);

    // Convertir los resultados a un array de datos y actualizar la caché
    querySnapshot.docs.forEach((doc) => {
      businessDetailsCache[doc.id] = {
        id: doc.id,
        ...doc.data(),
      };
    });

    // Devuelve los detalles combinados desde la caché y los nuevos datos
    return businessIds.map((id) => businessDetailsCache[id]);
  } catch (error) {
    console.error("Error fetching business details:", error);
    return businessIds.map((id) => businessDetailsCache[id] || null);
  }
}

export async function getAreas4Business(usertType, idBusiness, email) {
  if (usertType == "admin") {
    const areasRef = collection(db, "business", idBusiness, "areas");
    const areasSnapshot = await getDocs(areasRef);
    if (!areasSnapshot.empty) {
      const businessArray = areasSnapshot.docs.map((doc) => doc.data());
      return businessArray;
    } else {
      console.log("sin áreas");
      return [];
    }
  } else if (usertType == "agent") {
    const areasRef = collection(
      db,
      "business",
      idBusiness,
      "agents",
      email,
      "areas"
    );
    const areasSnapshot = await getDocs(areasRef);
    if (!areasSnapshot.empty) {
      const areasArray = [];
      for (const docSnapshot of areasSnapshot.docs) {
        const areaId = docSnapshot.id;
        const areaRef = doc(db, "business", idBusiness, "areas", areaId);
        const areaSnapshot = await getDoc(areaRef);
        if (areaSnapshot.exists()) {
          const areaData = areaSnapshot.data();
          if (areaData && areaData.icon) {
            areasArray.push(areaData);
          } else {
            console.log(
              `Datos de área incompletos para el área con ID: ${areaId}`
            );
          }
        }
      }
      return areasArray;
    } else {
      console.log("sin áreas");
      return [];
    }
  }
}

export async function getResponsable(
  businessEmail,
  dpto,
  competencia,
  emailResponsable
) {
  // Referencia a la colección de agentes
  const agentesRef = collection(db, `business/${businessEmail}/agents`);
  const querySnapshot = await getDocs(agentesRef);
  const responsables = [];

  for (const docSnap of querySnapshot.docs) {
    // Referencia a la subcolección de áreas del agente
    const areasRef = collection(
      db,
      `business/${businessEmail}/agents/${docSnap.id}/areas`
    );
    const areasSnapshot = await getDocs(areasRef);

    // Verificar si alguna de las áreas coincide con 'dpto'
    let areaCoincide = false;
    areasSnapshot.forEach((areaDoc) => {
      if (areaDoc.data().area === dpto) {
        areaCoincide = true;
        console.log(areaCoincide);
      }
    });

    if (areaCoincide) {
      const competenciasRef = collection(
        db,
        `business/${businessEmail}/agents/${docSnap.id}/businessPartner/${emailResponsable}/competencias`
      );
      const competenciasSnapshot = await getDocs(competenciasRef);
      let competenciaCoincide = false;
      competenciasSnapshot.forEach((compDoc) => {
        // Asumiendo que cada documento dentro de 'competencias' representa una competencia
        // y tiene un campo 'nombre' que especifica el nombre de la competencia.
        if (compDoc.id === competencia) {
          // O compDoc.data().nombre si el nombre está en los datos
          competenciaCoincide = true;
        }
      });

      if (competenciaCoincide) {
        // Si el agente cubre la competencia, agrega su correo electrónico al array de responsables
        responsables.push(docSnap.id); // Asumiendo que el ID del documento es el correo electrónico del agente
      }
    }
  }

  return responsables;
}
/* export async function getResponsable(emailEmpresa, dpto, competencia) {
  // Define la competencia que quieres filtrar
  // Obtiene todos los agentes que están en el departamento deseado
  const agentesRef = collection(db, "clients", emailEmpresa, dpto);
  const agentesSnapshot = await getDocs(agentesRef);
  const responsables = [];

  // Para cada agente, verifica si la competencia especificada está presente
  for (const agente of agentesSnapshot.docs) {
    const competenciaRef = doc(db, "users", agente.id, "businessPartner", emailEmpresa, "competencias", competencia);
    const competenciaSnapshot = await getDoc(competenciaRef);
    if (competenciaSnapshot.exists()) {
      // Cambia esta línea para agregar solo el ID del agente al array
      responsables.push(agente.id);
    }
  }
  // Si el array responsables está vacío, devuelve "Por definir agente"
  if (responsables.length === 0) {
    return;
    console.log("no hat responsables")
  } else {
    return responsables;
    console.log("los responsables son" +responsables);
  }
} */

export async function getNumberOfTicketsForAgent(emailAgent) {
  const q = query(
    collection(db, "tickets"),
    where("to", "array-contains", emailAgent)
  );

  const querySnapshot = await getDocs(q);

  // El número de tickets es el número de documentos en la QuerySnapshot
  const numberOfTickets = querySnapshot.size;

  return numberOfTickets;
}

export async function getNumberTicketsAreas(area, emailBusiness) {
  const q = query(
    collection(db, "business", emailBusiness, "tickets"),
    where("department", "==", area)
  );

  const querySnapshot = await getDocs(q);

  // El número de tickets es el número de documentos en la QuerySnapshot
  const numberOfTickets = querySnapshot.size;

  return numberOfTickets;
}

export async function getPuntosSoportick(emailBusiness, emailUser, usertype) {
  // Verifica si el usuario es un agente antes de proceder.
  if (usertype !== "agent" && usertype !== "admin") {
    console.log("La función está restringida solo a agentes.");
    return "Acceso denegado"; // O maneja este caso como mejor te parezca.
  }

  const q = query(
    collection(db, `business/${emailBusiness}/tickets`),
    where("status", "==", "Archivado"),
    where("to", "array-contains", emailUser)
  );

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    await actualizarPuntosAgente(emailBusiness, emailUser, 0);
    return "0";
  }

  let sumatoriaCalificaciones = 0;
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    if (data.calificacion) {
      sumatoriaCalificaciones += data.calificacion;
    }
  });

  if (sumatoriaCalificaciones === 0 && !querySnapshot.empty) {
    await actualizarPuntosAgente(emailBusiness, emailUser, 0);
    return "sin calificaciones para mostrar";
  }

  // Llama a la función para actualizar los puntos del agente en la base de datos
  await actualizarPuntosAgente(
    emailBusiness,
    emailUser,
    sumatoriaCalificaciones
  );

  return sumatoriaCalificaciones;
}

async function actualizarPuntosAgente(
  emailBusiness,
  emailUser,
  puntos,
  category
) {
  // Verificar si el tipo de usuario es permitido para realizar la actualización.
  if (category !== "agent" && category !== "admin") {
    return "0"; // O maneja este caso como mejor te parezca.
  }

  if (category == "agent" && category == "admin") {
    const agentRef = doc(db, `business/${emailBusiness}/agents/${emailUser}`);

    try {
      await setDoc(agentRef, { puntos }, { merge: true });
    } catch (error) {}
  }
}

export async function deleteBoard(boardId) {
  const db = getFirestore();

  // Identifica todas las subcolecciones que necesitas eliminar
  const subCollections = ["tickets", "areas", "clients", "agents"];

  try {
    // Recorrer cada subcolección para eliminar sus documentos
    for (const subCollectionName of subCollections) {
      const subCollectionRef = collection(
        db,
        "business",
        boardId,
        subCollectionName
      );
      const snapshot = await getDocs(subCollectionRef);

      // Crear un batch de escritura para eliminar documentos
      const batch = writeBatch(db);
      snapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // Ejecutar el batch de escritura
      await batch.commit();
    }

    // Una vez eliminadas las subcolecciones, elimina el documento principal
    await deleteDoc(doc(db, "business", boardId));
  } catch (error) {
    console.error("Error al borrar el proyecto:", error);
    throw error; // Propagar el error para manejarlo en el cliente si es necesario
  }
}

export function businessDescription(idBusiness) {
  const q = doc(db, "business", idBusiness);
  return getDoc(q);
}

export async function getBusinessDescription(idBusiness) {
  const docSnapshot = await businessDescription(idBusiness);
  if (docSnapshot.exists()) {
    return docSnapshot.data().description;
  } else {
    return null;
  }
}

export async function getNumberOfMessages(businessEmail, ticketId) {
  const messagesCollection = collection(
    db,
    "business",
    businessEmail,
    "tickets",
    ticketId,
    "messages"
  );

  const querySnapshot = await getDocs(messagesCollection);

  // El número de mensajes es el número de documentos en la QuerySnapshot
  const numberOfMessages = querySnapshot.size;

  return numberOfMessages;
}

export async function updateParameters(
  userId,
  initView,
  workspaces,
  frequency
) {
  try {
    const refDoc = doc(db, "users", userId);
    await setDoc(
      refDoc,
      {
        initView: initView,
        workspaces: workspaces,
        frequency: frequency,
      },
      { merge: true }
    );
    return refDoc;
  } catch (error) {
    console.error("Error updating parameters: ", error);
    throw error;
  }
}

export async function updateTicket(businessEmail, ticketId, updatedData) {
  try {
    const formattedData = {};

    // Función auxiliar para validar y formatear fechas
    const formatDate = (date, fieldName) => {
      if (!moment(date, "DD-MM-YYYY", true).isValid()) {
        throw new Error(`Fecha de ${fieldName} inválida`);
      }
      return moment(date, "DD-MM-YYYY").format("DD-MM-YYYY");
    };

    if (updatedData.creacion) {
      formattedData.creacion = formatDate(updatedData.creacion, "creación");
    }

    const isAllDay =
      updatedData.horaInicio === "Todo el día" &&
      updatedData.horaTermino === "Todo el día";
    const isMultiDay =
      updatedData.vencimiento &&
      updatedData.fechaFin &&
      updatedData.vencimiento !== updatedData.fechaFin;

    if (isAllDay) {
      // Para eventos de todo el día, vencimiento y fechaFin deben ser iguales
      if (updatedData.vencimiento) {
        const formattedDate = formatDate(
          updatedData.vencimiento,
          "vencimiento"
        );
        formattedData.vencimiento = formattedDate;
        formattedData.fechaFin = formattedDate;
      } else if (updatedData.fechaFin) {
        const formattedDate = formatDate(updatedData.fechaFin, "finalización");
        formattedData.vencimiento = formattedDate;
        formattedData.fechaFin = formattedDate;
      }
    } else if (isMultiDay) {
      // Para eventos multidía, vencimiento es la fecha de inicio y fechaFin es la fecha de finalización
      formattedData.vencimiento = formatDate(
        updatedData.vencimiento,
        "vencimiento"
      );
      formattedData.fechaFin = formatDate(updatedData.fechaFin, "finalización");
    } else {
      // Para eventos regulares
      if (updatedData.vencimiento) {
        formattedData.vencimiento = formatDate(
          updatedData.vencimiento,
          "vencimiento"
        );
      }
      if (updatedData.fechaFin) {
        formattedData.fechaFin = formatDate(
          updatedData.fechaFin,
          "finalización"
        );
      }
    }

    if (updatedData.horaInicio && updatedData.horaTermino) {
      if (!isAllDay) {
        let horaInicio = moment(updatedData.horaInicio, "HH:mm");
        let horaTermino = moment(updatedData.horaTermino, "HH:mm");
        // Verificar si horaTermino es menor que horaInicio
        if (horaTermino.isSameOrBefore(horaInicio)) {
          // Ajustar horaTermino a horaInicio + 1 hora
          horaTermino = horaInicio.clone().add(1, "hour");
        }
        formattedData.horaInicio = horaInicio.format("HH:mm");
        formattedData.horaTermino = horaTermino.format("HH:mm");
      } else {
        formattedData.horaInicio = "Todo el día";
        formattedData.horaTermino = "Todo el día";
      }
    }

    if (updatedData.status !== undefined) {
      formattedData.status = updatedData.status;
    }
    if (updatedData.completado !== undefined) {
      formattedData.completado = updatedData.completado;
    }
    if (updatedData.observacion !== undefined) {
      formattedData.observacion = updatedData.observacion;
    }

    const ticketRef = doc(db, "business", businessEmail, "tickets", ticketId);
    await updateDoc(ticketRef, formattedData);
    console.log("Ticket actualizado correctamente");
  } catch (error) {
    console.error("Error al actualizar el ticket: ", error);
    throw error;
  }
}

export async function updateTicketDetalle(businessEmail, ticketId, updatedData, areas) {
  try {
    const formattedData = {};
    
    console.log('Datos recibidos:', updatedData); // Log inicial
    console.log('Areas disponibles:', areas); // Ver qué áreas tenemos

    // Función para formatear fechas
    const formatDate = (date, fieldName) => {
      if (!date) return null; 
      if (moment(date, "DD-MM-YYYY").isValid()) {
        return date;
      }
      if (!moment(date, "DD-MM-YYYY", true).isValid()) {
        console.warn(`Advertencia: Fecha de ${fieldName} en formato incorrecto:`, date);
        return null;
      }
      return moment(date, "DD-MM-YYYY").format("DD-MM-YYYY");
    };

    // Formatear fechas si existen
    if (updatedData.creacion) {
      formattedData.creacion = formatDate(updatedData.creacion, "creación");
    }
    if (updatedData.vencimiento) {
      formattedData.vencimiento = formatDate(updatedData.vencimiento, "vencimiento");
    }
    if (updatedData.fechaFin) {
      formattedData.fechaFin = formatDate(updatedData.fechaFin, "finalización");
    }

    // Función para procesar íconos en el título
    const processTitleIcons = (title, newIcon) => {
      if (!title) {
        return newIcon || "";
      }
      const doneIcon = "✅";
      let parts = title.trim().split(/\s+/);

      const isSingleEmoji = (str) => {
        return /^(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)$/u.test(str);
      };

      if (parts[0] === doneIcon) {
        if (parts.length > 1 && isSingleEmoji(parts[1])) {
          if (newIcon) {
            parts[1] = newIcon;
          } else {
            parts.splice(1, 1);
          }
        } else {
          if (newIcon) {
            parts.splice(1, 0, newIcon);
          }
        }
      } else {
        if (isSingleEmoji(parts[0])) {
          if (newIcon) {
            parts[0] = newIcon;
          } else {
            parts.splice(0, 1);
          }
        } else {
          if (newIcon) {
            parts.unshift(newIcon);
          }
        }
      }
      return parts.join(" ");
    };

    // Manejar el título y el ícono
    if (updatedData.title) {
      const newIcon = updatedData.department 
        ? (areas?.find(area => area.name === updatedData.department)?.icon || '') 
        : '';

      formattedData.title = processTitleIcons(updatedData.title, newIcon);
    }

    // Actualizar área y department juntos para mantener consistencia
    if (updatedData.department) {
      const selectedArea = areas?.find(area => area.name === updatedData.department);
      console.log('Área seleccionada:', selectedArea); // Ver qué área se seleccionó

      if (selectedArea) {
        formattedData.department = selectedArea.name;
        formattedData.area = selectedArea.name;
        formattedData.color = selectedArea.color;
        console.log('Asignando área y department:', selectedArea.name);
      } else {
        console.warn('No se encontró el área seleccionada:', updatedData.department);
      }
    }

    // Forzar la actualización de área y department incluso si están vacíos
    formattedData.area = updatedData.area || '';
    formattedData.department = updatedData.department || '';

    // Copiar otros campos relevantes
    const fieldsToUpdate = [
      "descripcion",
      "status",
      "priority",
      "horaInicio",
      "horaTermino",
      "allDay",
      "type",
      "color",
      "title"
    ];

    fieldsToUpdate.forEach(field => {
      if (updatedData[field] !== undefined) {
        formattedData[field] = updatedData[field];
      }
    });

    // Timestamp de última actualización
    formattedData.lastUpdated = serverTimestamp();

    console.log('Datos finales a actualizar:', formattedData);

    // Actualizar en Firebase
    const ticketRef = doc(db, "business", businessEmail, "tickets", ticketId);
    await updateDoc(ticketRef, formattedData);

    // Verificar la actualización
    const updatedDoc = await getDoc(ticketRef);
    console.log('Documento actualizado en Firebase:', updatedDoc.data());

    return formattedData;
  } catch (error) {
    console.error("Error detallado al actualizar el ticket:", error);
    throw error;
  }
}

export const loadData = async (emailBusiness, id) => {
  try {
    const docRef = doc(db, "business", emailBusiness, "tickets", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    }
    return null;
  } catch (error) {
    console.error("Error loading data: ", error);
    return null;
  }
};

export const saveData = async (emailBusiness, id, items) => {
  try {
    const docRef = doc(db, "business", emailBusiness, "tickets", id);
    await updateDoc(docRef, { items });
  } catch (error) {
    console.error("Error saving data: ", error);
  }
};

export const uploadFile = async (file, filePath) => {
  const user = auth.currentUser;
  if (user) {
    const storageRef = ref(storage, filePath);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  } else {
    throw new Error("User not authenticated");
  }
};

export const createNotification = async (
  userEmail,
  title,
  message,
  workspaceData,
  type,
  department,
  emailBusiness
) => {
  try {
    await addDoc(collection(db, `users/${userEmail}/notifications`), {
      title: title,
      message: message,
      timestamp: serverTimestamp(),
      read: false,
      workspaceData: workspaceData,
      type: type,
      department: department,
      emailBusiness: emailBusiness,
    });
  } catch (error) {
    console.error("Error creating notification: ", error);
  }
};

export const deleteFile = async (fileUrl) => {
  try {
    const decodedUrl = decodeURIComponent(fileUrl);

    // Extraer el path relativo del archivo en el storage de Firebase
    const filePathStartIndex = decodedUrl.indexOf("/o/") + 3;
    const filePathEndIndex = decodedUrl.indexOf("?alt=");
    const filePath = decodedUrl
      .substring(filePathStartIndex, filePathEndIndex)
      .replace(/%2F/g, "/");

    const fileRef = ref(storage, filePath);
    await deleteObject(fileRef);
    console.log(`Archivo ${filePath} eliminado con éxito.`);
  } catch (error) {
    console.error("Error eliminando el archivo: ", error);
  }
};

export const getTareasCalendar = (
  category,
  businessEmail,
  department,
  estadoTarea,
  userEmail,
  setTickets
) => {
  const { q1, q2, q3 } = queryGetTickets(
    category,
    businessEmail,
    department,
    estadoTarea,
    userEmail
  );

  let items1 = [];
  let items2 = [];
  let items3 = [];

  const unsubscribe1 = onSnapshot(q1, (querySnapshot) => {
    items1 = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setTickets([...items1, ...items2, ...items3]);
  });

  let unsubscribe2 = () => {};
  if (q2) {
    unsubscribe2 = onSnapshot(q2, (querySnapshot2) => {
      items2 = querySnapshot2.docs
        .map((doc) => {
          if (!items1.some((ticket) => ticket.id === doc.id)) {
            return { id: doc.id, ...doc.data() };
          }
        })
        .filter(Boolean);
      setTickets([...items1, ...items2, ...items3]);
    });
  }

  let unsubscribe3 = () => {};
  if (q3) {
    unsubscribe3 = onSnapshot(q3, (querySnapshot2) => {
      items3 = querySnapshot2.docs
        .map((doc) => {
          if (
            !items1.some((ticket) => ticket.id === doc.id) &&
            !items2.some((ticket) => ticket.id === doc.id)
          ) {
            return { id: doc.id, ...doc.data() };
          }
        })
        .filter(Boolean);
      setTickets((prevTickets) => [...items1, ...items2, ...items3]);
    });
  }

  return () => {
    unsubscribe1();
    unsubscribe2();
    unsubscribe3();
  };
};

export const enterFullScreen = (element) => {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    // Firefox
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    // Chrome, Safari and Opera
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    // IE/Edge
    element.msRequestFullscreen();
  }
};

export const exitFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    // Firefox
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    // Chrome, Safari and Opera
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    // IE/Edge
    document.msExitFullscreen();
  }
};

export const obtenerTicketsDia = (tickets, dia, mes, anio) => {
  // Validar que tickets sea un array válido
  if (!tickets || !Array.isArray(tickets)) {
    console.warn("Tickets inválidos en obtenerTicketsDia:", tickets);
    return [];
  }

  return tickets.filter((ticket) => {
    // Validar que el ticket y su vencimiento existan y sean válidos
    if (!ticket || !ticket.vencimiento || typeof ticket.vencimiento !== 'string') {
      console.warn("Ticket o vencimiento inválido:", ticket);
      return false;
    }

    try {
      const [ticketDia, ticketMes, ticketAnio] = ticket.vencimiento.split("-");
      
      // Validar que los valores sean números válidos
      if (!ticketDia || !ticketMes || !ticketAnio) {
        console.warn("Fecha de vencimiento incompleta:", ticket.vencimiento);
        return false;
      }

      const fechaTicket = new Date(ticketAnio, ticketMes - 1, ticketDia);
      const fechaActual = new Date(anio, mes, dia);
      
      // Validar que las fechas sean válidas
      if (isNaN(fechaTicket.getTime()) || isNaN(fechaActual.getTime())) {
        console.warn("Fecha inválida para ticket:", ticket);
        return false;
      }

      return fechaTicket.toDateString() === fechaActual.toDateString();
    } catch (error) {
      console.error("Error al procesar fecha del ticket:", error, ticket);
      return false;
    }
  });
};

export const obtenerEventosDelDia = async (
  category,
  businessEmail,
  department,
  estadoTarea,
  userEmail,
  diaActual,
  mesActual,
  anioActual
) => {
  return new Promise((resolve, reject) => {
    const setTickets = (tickets) => {
      const eventosDelDia = tickets.filter((evento) => {
        const [dia, mes, anio] = evento.vencimiento.split("-");
        return (
          parseInt(dia) === diaActual &&
          parseInt(mes) === mesActual + 1 && // meses en JavaScript son 0-11
          parseInt(anio) === anioActual
        );
      });
      resolve(eventosDelDia);
    };

    const unsubscribe = getTareasCalendar(
      category,
      businessEmail,
      department,
      estadoTarea,
      userEmail,
      setTickets
    );

    // Optional: Cleanup function if needed
    return () => {
      unsubscribe();
    };
  });
};

export const calcularRowSpan = (inicio, fin) => {
  const inicioEnMinutos = inicio.getHours() * 60 + inicio.getMinutes();
  const finEnMinutos = fin.getHours() * 60 + fin.getMinutes();
  return Math.ceil((finEnMinutos - inicioEnMinutos) / 60);
};

export const actualizarTicket = async (
  updateTicket,
  businessEmail,
  ticketId,
  updatedData,
  setSelectedTarea
) => {
  try {
    await updateTicket(businessEmail, ticketId, updatedData);
    setSelectedTarea(null);
  } catch (error) {
    console.error("Error actualizando el ticket:", error);
  }
};

export const onDropTicket = async (
  ticket,
  dia,
  mes,
  anio,
  hour,
  offsetY,
  isAllDay,
  updateTicket,
  businessEmail,
  setSelectedTarea
) => {
  const timezone = "America/Santiago";
  let newStartTime, newEndTime;

  if (isAllDay) {
    newStartTime = "Todo el día";
    newEndTime = "Todo el día";
  } else {
    const isHalfHour = offsetY > 25;
    newStartTime = moment.tz(
      { year: anio, month: mes, day: dia, hour: hour },
      timezone
    );
    if (isHalfHour) newStartTime.add(30, "minutes");
    newEndTime = newStartTime.clone().add(1, "hour");
    newStartTime = newStartTime.format("HH:mm");
    newEndTime = newEndTime.format("HH:mm");
  }

  const updatedData = {
    creacion: moment
      .tz({ year: anio, month: mes, day: dia }, timezone)
      .format("DD-MM-YYYY"),
    vencimiento: moment
      .tz({ year: anio, month: mes, day: dia }, timezone)
      .format("DD-MM-YYYY"),
    horaInicio: newStartTime,
    horaTermino: newEndTime,
  };

  await actualizarTicket(
    updateTicket,
    businessEmail,
    ticket.id,
    updatedData,
    setSelectedTarea
  );
};

export const onResizeStop = async (
  ticket,
  newHeight,
  updateTicket,
  businessEmail,
  setSelectedTarea
) => {
  const totalMinutes = (newHeight / 50) * 60;
  const newEndTime = moment(ticket.horaInicio, "HH:mm").add(
    totalMinutes,
    "minutes"
  );
  const updatedData = { ...ticket, horaTermino: newEndTime.format("HH:mm") };

  await actualizarTicket(
    updateTicket,
    businessEmail,
    ticket.id,
    updatedData,
    setSelectedTarea
  );
  await updateGoogleCalendarEvent(updatedData, ticket, ticket.color);
};

export const agruparTicketsPorHora = (ticketsDia) => {
  const grupos = {};
  ticketsDia.forEach((ticket) => {
    const key = `${ticket.horaInicio}-${ticket.horaTermino}`;
    if (!grupos[key]) grupos[key] = [];
    grupos[key].push(ticket);
  });
  return grupos;
};

export const obtenerColorTicket = (index, dia, coloresTicket) => {
  return coloresTicket[(index + dia) % coloresTicket.length];
};

//Función para procesar la fecha de las tareas

export const processFecha = async (
  evento,
  fecha,
  horaInicio,
  horaTermino,
  mensaje
) => {
  // Función para obtener la fecha actual en la zona horaria de Chile
  const fechaActualChile = () => {
    const fechaChile = moment().tz("America/Santiago").format("YYYY-MM-DD");
    return fechaChile;
  };

  // Fecha actual en Chile
  const hoy = fechaActualChile();

  // Hora actual en formato HH:MM:SS
  const horaActual = new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  // Switch para manejar diferentes tipos de eventos
  switch (evento) {
    case "tarea":
      // Caso 1: Es una tarea con hora de inicio definida, devuelvo la misma hora, fecha y tipo recibido.
      if (horaInicio && horaInicio !== "Todo el día" && fecha) {
        return {
          tipo: "tarea",
          horaInicio: horaInicio,
          horaTermino: "tarea",
          fecha: fecha,
        };
      }
      // Caso 2: Es una tarea sin hora de inicio definida, creada desde el menú de creación simple.
      if (!horaInicio && !fecha) {
        const Newfecha = await getFecha(mensaje, "inicio", hoy);
        return {
          tipo: "tarea",
          horaInicio: "Todo el día",
          fecha: Newfecha,
        };
      }
      // Caso 3: Es una tarea se recibe la fecha, pero no se recibe la hora de inicio
      if (!horaInicio && fecha) {
        return {
          tipo: "tarea",
          horaInicio: "Todo el día",
          horaTermino: "tarea",
          fecha: fecha,
        };
      }
      // Caso 4: Es una tarea se recibe la fecha, y es todo el día
      if (horaInicio === "Todo el día" && fecha) {
        return {
          tipo: "tarea",
          horaInicio: "Todo el día",
          horaTermino: "Todo el día",
          fecha: fecha,
        };
      }
      break;

    case "reunión":
      // Aquí puedes agregar la lógica específica para el caso 'reunión'
      if (horaInicio && fecha) {
        return {
          tipo: "reunión",
          horaInicio: horaInicio,
          horaTermino: horaTermino || "Hora no definida",
          fecha: fecha,
        };
      }
      break;

    case "evento":
      // Lógica para el caso 'evento'
      if (fecha) {
        return {
          tipo: "evento",
          fecha: fecha,
          horaInicio: horaInicio || "Todo el día",
          horaTermino: horaTermino || "Todo el día",
        };
      }
      break;

    default:
      // Lógica para el caso por defecto
      return {
        tipo: "desconocido",
        mensaje: "Evento desconocido",
      };
  }
};

// Function to get a valid access token
export const getValidAccessToken = async (email, accessToken, refreshToken, tokenExpiry, firebaseAccessToken) => {
  const firebaseToken = await auth.currentUser.getIdToken()

  if (!tokenExpiry) {
    throw new Error("User email, access token, refresh token, and token expiry are required.");
  }

  const currentTime = Date.now();

  // Verifica si el accessToken ha expirado
  if (currentTime >= tokenExpiry) {
  // if (currentTime) {
    try {
      console.log("Llamando a la renovación de token.......");
      const refreshResponse = await fetch(`https://us-central1-planiusai.cloudfunctions.net/api/refreshToken`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${firebaseToken}`
        }      
      });
    
      if (!refreshResponse.ok) {
        throw new Error(`Error refreshing access token: ${refreshResponse.status}`);
      }
    
      const {access_token, expiry_date} = await refreshResponse.json();
 
      // Retorna el nuevo accessToken
      return access_token;
    } catch (error) {
      console.error("Error refreshing access token:", error);
      throw error;
    }    
  }
  
  // Si el token aún es válido, retorna el accessToken existente
  return accessToken;
};

// export const getValidAccessToken = async () => {
// //   try {
//     // Retrieve tokens from sessionStorage
//     return accessToken.getItem("accessToken");
//     let accessToken = sessionStorage.getItem("refreshToken");
//     let expiryDate = sessionStorage.getItem("expiryDate") | 0;

//     const now = Date.now();
//     console.log("NOWWW", now);

//     // Check if the access token is missing or expired
//     if (!accessToken || !expiryDate || now >= Number(expiryDate)) {
//       // Access token is missing or expired, request a new one from the server
//       const response = await axios.post(
//         "https://us-central1-planiusai.cloudfunctions.net/api/refreshToken",
//         {
//           refresh_token: accessToken,
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to refresh access token.");
//       }

//       const data = await response.json();
//       accessToken = data.access_token;
//       expiryDate = data.expiry_date;

//       // Store the new access token and expiry date in sessionStorage
//       sessionStorage.setItem("accessToken", accessToken);
//       sessionStorage.setItem("expiryDate", expiryDate);
//     }

//     return accessToken;
//   } catch (error) {
//     console.error("Error obtaining valid access token:", error);
//     throw error;
//   }
// };

export const getTicketsCalendar = (
  category,
  businessEmail,
  department,
  userEmail,
  setTickets
) => {
  const { q1, q2, q3 } = queryGetTicketsCalendar(
    category,
    businessEmail,
    department,
    userEmail
  );

  let items1 = [];
  let items2 = [];
  let items3 = [];

  const unsubscribe1 = onSnapshot(q1, (querySnapshot) => {
    items1 = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setTickets((prevTickets) => [...items1, ...items2, ...items3]);
  });

  let unsubscribe2 = () => {};
  if (q2) {
    unsubscribe2 = onSnapshot(q2, (querySnapshot2) => {
      items2 = querySnapshot2.docs
        .map((doc) => {
          if (!items1.some((ticket) => ticket.id === doc.id)) {
            return { id: doc.id, ...doc.data() };
          }
        })
        .filter(Boolean);
      setTickets((prevTickets) => [...items1, ...items2, ...items3]);
    });
  }

  let unsubscribe3 = () => {};
  if (q3) {
    unsubscribe3 = onSnapshot(q3, (querySnapshot3) => {
      items3 = querySnapshot3.docs
        .map((doc) => {
          if (
            !items1.some((ticket) => ticket.id === doc.id) &&
            !items2.some((ticket) => ticket.id === doc.id)
          ) {
            return { id: doc.id, ...doc.data() };
          }
        })
        .filter(Boolean);
      setTickets((prevTickets) => [...items1, ...items2, ...items3]);
    });
  }

  return () => {
    unsubscribe1();
    unsubscribe2();
    unsubscribe3();
  };
};

export function queryGetTicketsCalendar(
  category,
  emailBusiness,
  department,
  emailAgent
) {
  let q1, q2, q3;

  if (category === "admin") {
    q1 = query(
      collection(db, "business", emailBusiness, "tickets"),
      and(
        where("emailBusiness", "==", emailBusiness),
        where("status", "in", ["Proceso", "Terminado"]),
        or(
          where("department", "==", department),
          where("to", "array-contains", emailAgent)
        )
      ),
      orderBy("creacion", "desc")
    );

    q2 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "in", ["Proceso", "Terminado"]),
      where("from", "==", emailAgent),
      orderBy("creacion", "desc")
    );

    q3 = null;

    return { q1, q2, q3 };
  }

  if (category === "supervisor") {
    q1 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "in", ["Proceso", "Terminado"]),
      where("to", "array-contains", emailAgent),
      orderBy("creacion", "desc")
    );

    q2 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "in", ["Proceso", "Terminado"]),
      where("emailClient", "==", emailAgent),
      orderBy("creacion", "desc")
    );

    q3 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "in", ["Proceso", "Terminado"]),
      where("emailBusiness", "==", emailBusiness),
      where("department", "==", department),
      orderBy("creacion", "desc")
    );

    return { q1, q2, q3 };
  }

  if (category === "agent" || category === "cliente") {
    q1 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "in", ["Proceso", "Terminado"]),
      where("to", "array-contains", emailAgent),
      orderBy("creacion", "desc")
    );

    q2 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "in", ["Proceso", "Terminado"]),
      where("emailClient", "==", emailAgent),
      orderBy("creacion", "desc")
    );

    q3 = query(
      collection(db, "business", emailBusiness, "tickets"),
      where("status", "in", ["Proceso", "Terminado"]),
      where("department", "==", department),
      where("from", "==", emailAgent),
      orderBy("creacion", "desc")
    );

    return { q1, q2, q3 };
  }
}
