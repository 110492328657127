import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { obtenerCalendariosDeUsuario } from './FunctionsCalendar';
import { 
  doc, 
  updateDoc, 
  getDoc, 
  collection, 
  writeBatch, 
  arrayUnion,
  getDocs,
  query,
  where
} from 'firebase/firestore';
import { db } from '../firebase';
import './GestionCalendarios.css';
import { Calendar, Trash2, Plus } from 'lucide-react';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import ICAL from 'ical.js';
import { toast } from 'react-toastify';
import moment from 'moment';

const GestionCalendarios = () => {
  const { currentUser, accessToken, refreshToken, tokenExpiry, businessEmail } = useContext(AuthContext);
  const [calendarios, setCalendarios] = useState([]);
  const [selectedCalendars, setSelectedCalendars] = useState([]);
  const [calendarColors, setCalendarColors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [importUrls, setImportUrls] = useState({});
  const [isImporting, setIsImporting] = useState(false);
  const [userData, setUserData] = useState(null);

  const predefinedColors = [
    '#7986CB', '#66C18C', '#B084C8', '#E67C73', '#F6BF26', 
    '#F4511E', '#67C3EB', '#616161', '#3F51B5', '#4CAF50',
    '#EF5350', '#8BC34A', '#9C27B0', '#FF7F50', '#00BCD4',
    '#FBC02D', '#CE93D8', '#4DD0E1', '#FFAB91', '#5C6BC0'
  ];

  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
        const googleCalendars = await obtenerCalendariosDeUsuario(
          currentUser, 
          accessToken, 
          refreshToken, 
          tokenExpiry
        );

        // Obtener datos del usuario
        const userDoc = await getDoc(doc(db, 'users', currentUser.email));
        const userDataFromFirebase = userDoc.data();

        // Obtener datos del business
        const businessDoc = await getDoc(doc(db, 'business', businessEmail));
        const businessData = businessDoc.data();
        
        // Verificar calendarios importados tanto en users como en business
        const importedCalendars = {};
        
        // Verificar en la colección users
        if (userDataFromFirebase?.importedCalendars) {
          Object.assign(importedCalendars, userDataFromFirebase.importedCalendars);
        }
        
        // Verificar en la colección business
        if (businessData?.importedCalendarUrls) {
          Object.keys(businessData.importedCalendarUrls).forEach(calendarId => {
            importedCalendars[calendarId] = true;
          });
        }

        // Verificar en tickets si hay eventos importados
        for (const calendar of googleCalendars) {
          if (calendar.id.includes('@import.calendar.google.com')) {
            const ticketsSnapshot = await getDocs(
              query(
                collection(db, 'business', businessEmail, 'tickets'),
                where('importedCalendarId', '==', calendar.id)
              )
            );
            
            if (!ticketsSnapshot.empty) {
              importedCalendars[calendar.id] = true;
            }
          }
        }

        setSelectedCalendars(userDataFromFirebase?.calendariosSeleccionados || []);
        setCalendarColors(userDataFromFirebase?.calendarColors || {});
        
        // Actualizar userData con los calendarios importados verificados
        setUserData({
          ...userDataFromFirebase,
          importedCalendars
        });

        const validCalendars = googleCalendars.filter(calendar => 
          !calendar.id.includes("weather") && 
          !calendar.id.includes("holiday") && 
          calendar.id.length > 0
        );

        setCalendarios(validCalendars);
      } catch (error) {
        console.error("Error al cargar los calendarios:", error);
        toast.error("Error al cargar los calendarios");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCalendarData();
  }, [currentUser, accessToken, refreshToken, tokenExpiry, businessEmail]);

  useEffect(() => {
    console.log('Valores del contexto:', {
      currentUser,
      accessToken,
      businessEmail
    });
  }, [currentUser, accessToken, businessEmail]);

  const handleToggleCalendar = (calendarId) => {
    if (calendarId === currentUser.email) return; // No permitir desactivar el calendario principal

    setSelectedCalendars(prev => 
      prev.includes(calendarId)
        ? prev.filter(id => id !== calendarId)
        : [...prev, calendarId]
    );
  };

  const handleColorChange = (calendarId, color) => {
    setCalendarColors(prev => ({
      ...prev,
      [calendarId]: color
    }));
  };

  const saveChanges = async () => {
    setIsSaving(true);
    try {
      const userRef = doc(db, 'users', currentUser.email);
      await updateDoc(userRef, {
        calendariosSeleccionados: selectedCalendars,
        calendarColors: calendarColors,
      });
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const getIcalUrl = (calendarId) => {
    return `https://calendar.google.com/calendar/ical/${encodeURIComponent(calendarId)}/public/basic.ics`;
  };

  const importCalendarFromUrl = async (calendarId, color) => {
    if (userData?.importedCalendars?.[calendarId]) {
      toast.warning('Este calendario ya ha sido importado anteriormente');
      return;
    }

    setIsImporting(true);
    try {
      const url = getIcalUrl(calendarId);
      const functionUrl = 'https://us-central1-planiusai.cloudfunctions.net/calendar';
      
      const response = await fetch(
        `${functionUrl}?url=${encodeURIComponent(url)}&accessToken=${accessToken}&businessEmail=${businessEmail}&userEmail=${currentUser.email}&color=${encodeURIComponent(color)}`,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Origin': window.location.origin
          },
          mode: 'cors',
          credentials: 'same-origin'
        }
      );

      if (!response.ok) {
        throw new Error('Error al importar el calendario');
      }

      const events = await response.json();
      
      const batch = writeBatch(db);
      
      const userRef = doc(db, 'users', currentUser.email);
      const businessRef = doc(db, 'business', businessEmail);
      
      batch.update(userRef, {
        [`importedCalendars.${calendarId}`]: true
      });
      
      batch.update(businessRef, {
        [`importedCalendarUrls.${calendarId}`]: url
      });
      
      await batch.commit();

      setUserData(prev => ({
        ...prev,
        importedCalendars: {
          ...(prev?.importedCalendars || {}),
          [calendarId]: true
        }
      }));

      toast.success(`Calendario importado con ${events.length} eventos`);
    } catch (error) {
      console.error('Error al importar calendario:', error);
      toast.error('Error al importar el calendario');
    } finally {
      setIsImporting(false);
    }
  };

  return (
    <div>
      <Navbar />
      <Sidebar />
      <div>
        <div className="gestion-calendarios-container">
          <div className="gestion-calendarios-header">
            <h2 className="form-title">
              <Calendar className="header-icon" /> Gestión de Calendarios
            </h2 >
            <p className="intro-text-container" style={{ maxWidth: '1200px', margin:"auto", marginBottom:"1rem", textAlign:"left"}}>
              Elige qué calendarios de Google sincronizar y asígnales un color. Por ejemplo: eventos del calendario 
              "Universidad" en rojo y "Deportes" en azul.
            </p>
          </div>

          {isLoading ? (
            <div className="gestion-calendarios-loading">
              <div className="loading-spinner"></div>
              <p>Cargando calendarios...</p>
            </div>
          ) : (
            <div className="calendarios-scroll">
              <div className="calendarios-grid">
                {calendarios.map((calendar) => (
                  <div 
                    key={calendar.id} 
                    className={`calendario-card ${
                      selectedCalendars.includes(calendar.id) ? 'active' : ''
                    } ${calendar.id.includes('@import.calendar.google.com') ? 'imported' : ''}`}
                  >
                    <div className="calendario-info">
                      <div className="calendario-header">
                        <h3>{calendar.summary}</h3>
                        <button 
                          className="toggle-button"
                          onClick={() => handleToggleCalendar(calendar.id)}
                          disabled={calendar.id === currentUser.email}
                        >
                          {selectedCalendars.includes(calendar.id) 
                            ? <Trash2 size={20} /> 
                            : <Plus size={20} />
                          }
                        </button>
                      </div>
                    </div>

                    {selectedCalendars.includes(calendar.id) && (
                      <>
                        <div className="color-selector">
                          <span>Color del calendario:</span>
                          <div className="color-grid">
                            {predefinedColors.map((color) => (
                              <button
                                key={color}
                                className={`color-option ${calendarColors[calendar.id] === color ? 'selected' : ''}`}
                                style={{ backgroundColor: color }}
                                onClick={() => handleColorChange(calendar.id, color)}
                              />
                            ))}
                          </div>
                        </div>

                        {calendar.id.includes('@import.calendar.google.com') && (
                          <div className="import-form">
                            {!userData?.importedCalendars?.[calendar.id] ? (
                              <button
                                onClick={() => importCalendarFromUrl(calendar.id, calendarColors[calendar.id] || '#5C6BC0')}
                                disabled={isImporting}
                                className="import-button"
                              >
                                {isImporting ? 'Importando...' : 'Importar Calendario Externo'}
                              </button>
                            ) : (
                              <div className="imported-badge">
                                Calendario importado ✓
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>

              <button 
                className="formButton" 
                onClick={saveChanges}
                disabled={isSaving}
                style={{ 
                  marginTop: '1rem',
                  display: 'block',
                  margin: '1rem auto',
                  width: 'fit-content'
                }}
              >
                {isSaving ? 'Guardando...' : 'Guardar Cambios'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GestionCalendarios; 