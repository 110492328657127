import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import './AudioRecoder.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AudioRecorder = forwardRef(
  ({ onTranscription, customButton, onAudioLevel }, ref) => {
    const [isRecording, setIsRecording] = useState(false);
    const [loading, setLoading] = useState(false);
    const [counter, setCounter] = useState(30);
    const recorderRef = useRef(null);
    const streamRef = useRef(null);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const animationFrameIdRef = useRef(null);
    const timerRef = useRef(null); // Referencia para el timer
    const isRecordingRef = useRef(isRecording);

    // Actualizar la referencia de isRecording para usarla en callbacks asíncronos
    useEffect(() => {
      isRecordingRef.current = isRecording;
    }, [isRecording]);

    useImperativeHandle(ref, () => ({
      startRecording,
      stopRecording,
      sendAudioToServer,
      cancelRecording,
    }));

    // Función para limpiar el timer
    const clearTimer = () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };

    const analyzeAudio = (stream) => {
      // Si existe un AudioContext previo, se intenta cerrarlo si aún no está cerrado
      if (audioContextRef.current) {
        try {
          if (audioContextRef.current.state !== 'closed') {
            audioContextRef.current.close().catch((e) =>
              console.warn('Error al cerrar AudioContext previo:', e)
            );
          }
        } catch (e) {
          console.warn('Error al cerrar AudioContext previo:', e);
        }
      }
      const AudioContext =
        window.AudioContext || window.webkitAudioContext;
      const audioContext = new AudioContext();
      audioContextRef.current = audioContext;
      const analyser = audioContext.createAnalyser();
      analyserRef.current = analyser;
      const microphone = audioContext.createMediaStreamSource(stream);
      microphone.connect(analyser);
      analyser.fftSize = 256;
      analyser.smoothingTimeConstant = 0.5;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const updateLevel = () => {
        if (!isRecordingRef.current) {
          if (
            audioContextRef.current &&
            audioContextRef.current.state !== 'closed'
          ) {
            audioContextRef.current.close().catch((e) =>
              console.warn('Error al cerrar AudioContext:', e)
            );
          }
          return;
        }
        analyser.getByteFrequencyData(dataArray);
        const levels = [];
        const numBars = 8;
        const segmentLength = Math.floor(bufferLength / numBars);
        for (let i = 0; i < numBars; i++) {
          const start = i * segmentLength;
          let sum = 0;
          for (let j = start; j < start + segmentLength; j++) {
            sum += dataArray[j];
          }
          let average = sum / segmentLength;
          let level = average / 255;
          level = Math.min(1, level * 5);
          levels.push(level);
        }
        if (onAudioLevel) {
          onAudioLevel(levels);
        }
        animationFrameIdRef.current = requestAnimationFrame(updateLevel);
      };

      updateLevel();
    };

    const startRecording = async () => {
      if (
        !navigator.mediaDevices ||
        !navigator.mediaDevices.getUserMedia
      ) {
        toast.error('MediaDevices API no está disponible.');
        return;
      }
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
        });
        streamRef.current = stream;
        analyzeAudio(stream);
        recorderRef.current = new RecordRTC(stream, {
          type: 'audio',
          mimeType: 'audio/webm',
          numberOfAudioChannels: 1,
          desiredSampRate: 16000, // Tasa de muestreo recomendada para Whisper
          recorderType: StereoAudioRecorder,
        });
        recorderRef.current.startRecording();
        setIsRecording(true);
        setCounter(30);

        // Iniciar el timer de 30 segundos como medida de seguridad.
        clearTimer();
        timerRef.current = setTimeout(() => {
          if (isRecordingRef.current) {
            // Si llega a 30 segundos sin que el usuario detenga, se detiene automáticamente.
            handleStopRecording();
          }
        }, 30000);
      } catch (error) {
        toast.error('Error al iniciar la grabación: ' + error.message);
      }
    };

    // Función que se invoca al presionar el botón de detener
    const handleStopRecording = () => {
      clearTimer();
      setCounter(0);
      stopRecording()
        .then((audioBlob) => {
          return sendAudioToServer(audioBlob);
        })
        .then((text) => {
          // Si la API devuelve respuesta vacía, se muestra un error.
          if (!text || text.trim() === '') {
            toast.error('Error: respuesta vacía de la API.');
          }
        })
        .catch((error) => {
          console.error('Error al detener la grabación:', error);
          toast.error('Error al detener la grabación: ' + error.message);
        });
    };

    const stopRecording = () => {
      return new Promise((resolve, reject) => {
        if (!recorderRef.current) {
          reject(new Error('No hay grabación en curso.'));
          return;
        }
        recorderRef.current.stopRecording(() => {
          const audioBlob = recorderRef.current.getBlob();
          // Limpiar recursos del recorder y stream
          if (recorderRef.current) {
            recorderRef.current.destroy();
            recorderRef.current = null;
          }
          if (streamRef.current) {
            streamRef.current.getTracks().forEach((track) => track.stop());
            streamRef.current = null;
          }
          if (animationFrameIdRef.current) {
            cancelAnimationFrame(animationFrameIdRef.current);
            animationFrameIdRef.current = null;
          }
          if (audioContextRef.current) {
            if (audioContextRef.current.state !== 'closed') {
              audioContextRef.current.close().catch((e) =>
                console.warn('Error al cerrar AudioContext:', e)
              );
            }
            audioContextRef.current = null;
          }
          setIsRecording(false);
          setCounter(0);
          resolve(audioBlob);
        });
      });
    };

    const cancelRecording = () => {
      clearTimer();
      if (!recorderRef.current) return;
      recorderRef.current.stopRecording(() => {
        if (recorderRef.current) {
          recorderRef.current.destroy();
          recorderRef.current = null;
        }
        if (streamRef.current) {
          streamRef.current.getTracks().forEach((track) => track.stop());
          streamRef.current = null;
        }
        if (animationFrameIdRef.current) {
          cancelAnimationFrame(animationFrameIdRef.current);
          animationFrameIdRef.current = null;
        }
        if (audioContextRef.current) {
          if (audioContextRef.current.state !== 'closed') {
            audioContextRef.current.close().catch((e) =>
              console.warn('Error al cerrar AudioContext:', e)
            );
          }
          audioContextRef.current = null;
        }
        setIsRecording(false);
        setCounter(0);
      });
    };

    const sendAudioToServer = async (audioBlob) => {
      setLoading(true);
      const reader = new FileReader();
      reader.readAsDataURL(audioBlob);
      return new Promise((resolve, reject) => {
        reader.onloadend = async () => {
          const base64AudioMessage = reader.result.split(',')[1];
          try {
            const response = await fetch(
              '/.netlify/functions/transcribeAudio',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ audioBase64: base64AudioMessage }),
              }
            );
            if (response.ok) {
              const data = await response.json();
              // Si la respuesta es vacía, se lanza un error.
              if (!data.text || data.text.trim() === '') {
                throw new Error('Respuesta vacía de la API.');
              }
              if (onTranscription) {
                onTranscription(data.text, audioBlob);
              }
              resolve(data.text);
            } else {
              const errorText = await response.text();
              throw new Error(errorText);
            }
          } catch (error) {
            toast.error('Error en la transcripción: ' + error.message);
            reject(error);
          } finally {
            setLoading(false);
          }
        };
      });
    };

    // Función para alternar la grabación según el estado actual.
    const toggleRecording = () => {
      if (isRecording) {
        // Si se está grabando, detener inmediatamente y enviar el audio.
        handleStopRecording();
      } else {
        startRecording();
      }
    };

    return customButton ? (
      customButton(isRecording, toggleRecording)
    ) : (
      <div className="audio-recorder-container">
        <button
          className={`ButtonGrabacion ${isRecording ? 'recording' : ''}`}
          onClick={toggleRecording}
          disabled={loading}
        >
          {isRecording ? 'Detener' : 'Grabar'}
        </button>
        <ToastContainer />
      </div>
    );
  }
);

export default AudioRecorder;
