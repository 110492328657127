// App.js
import React, { useContext, useState, useEffect, memo } from "react";
import "./style.scss";
import logo from "./components/img/logo-login.png";
import { Login } from "./components/Login";
import SignInWithGoogle from "./components/SingWithGoogle";
import { BrowserRouter, Routes, Route, Navigate, useParams } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import RegisterUser from "./components/RegisterUser";
import Areas from "./components/Areas";
import Registro from "./components/Registro";
import ImageUploader from "./components/ImageUploader";
import MyProfile from "./components/myProfile/MyProfile";
import RegistroUsuarios from "./components/RegistroUsuarios";
import Archivados from "./components/archivados/Archivados";
import TicketResume from "./components/IA/TicketResume";
import ReactGA from "react-ga4";
import SelectBusiness from "./components/selectBusiness/SelectBusiness";
import SelectArea from "./components/selectArea/SelectArea";
import CrearPerfil from "./components/CrearPerfil/CrearPerfil";
import Perfil from "./components/Perfil/Perfil";
import { GoogleAuth } from "./components/auth";
import Politicas from "./components/politicas/Politicas";
import Condiciones from "./components/condicionesDeServicio/Condiciones";
import WorkSpaces from "./components/workspaces/WorkSpaces";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Footer from "./components/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GestionCalendarios from "./components/calendario/GestionCalendarios";
import { collectionGroup, query, where, getDocs } from "firebase/firestore";
import { db } from "./components/firebase";
const LazyHome = React.lazy(() => import('./components/Home'));
const LazyStats = React.lazy(() => import('./components/Stats'));
const LazyGestionarTickets = React.lazy(() => import('./components/GestionarTickets'));
const LazyWorkSpaces = React.lazy(() => import('./components/workspaces/WorkSpaces'));

export const DarkModeContext = React.createContext();
export const DarkModeDispatchContext = React.createContext();

const MemoizedFooter = memo(Footer);

const ProtectedRoute = memo(({ children, currentUser }) => {
  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  return children;
});

// Agregar una nueva ruta pública para acceder al workspace por ID
const PublicWorkspace = () => {
  const { ticketId } = useParams();
  const [ticketData, setTicketData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTicketData = async () => {
      try {
        const ticketsRef = collectionGroup(db, 'tickets');
        const q = query(ticketsRef, where('idEventoCalendario', '==', ticketId));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const ticketDoc = querySnapshot.docs[0];
          const businessId = ticketDoc.ref.parent.parent.id;
          const ticketData = ticketDoc.data();
          
          setTicketData({
            ticketId: ticketDoc.id,
            businessId,
            title: ticketData.title,
            businessName: ticketData.businessName,
            priority: ticketData.priority,
            vencimiento: ticketData.vencimiento,
            creacion: ticketData.creacion,
            department: ticketData.department,
            status: ticketData.status,
            emailClient: ticketData.emailClient,
            emailBusiness: businessId,
            descripcion: ticketData.descripcion,
            context: ticketData.context,
            skill: ticketData.skill,
            plan: ticketData.plan,
            creator: ticketData.creator,
            to: ticketData.to || [],
            from: ticketData.from,
            horaCreacion: ticketData.horaCreacion,
            ...ticketData // incluir cualquier otro campo que pueda ser necesario
          });
        }
      } catch (error) {
        console.error("Error fetching ticket:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTicketData();
  }, [ticketId]);

  if (loading) {
    return (
      <div className="spinner-container">
        <img className="logo-init-container" src={logo} alt="Logo de Carga" />
        <div className="spinner"></div>
      </div>
    );
  }

  if (!ticketData) {
    return <div>Workspace no encontrado</div>;
  }

  return (
    <WorkSpaces 
      isPublic={true}
      ticketId={ticketData.ticketId}
      businessEmail={ticketData.businessId}
      location={{ state: ticketData }} // Pasar todos los datos del ticket como state
    />
  );
};

function App() {
  const { currentUser, userType } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [darkMode, setDarkMode] = useState(false);

  ReactGA.initialize("G-G5MSZYLLS1", { debug_mode: true });

  useEffect(() => {
    if (userType && currentUser) {
      setLoading(false);
      return;
    }
    
    const timeoutId = setTimeout(() => setLoading(false), 200);
    return () => clearTimeout(timeoutId);
  }, [userType, currentUser]);

  useEffect(() => {
    document.body.classList.toggle("dark-mode", darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const darkModeContextValue = React.useMemo(
    () => ({
      darkMode,
    }),
    [darkMode]
  );

  const darkModeDispatchValue = React.useMemo(
    () => ({
      toggleDarkMode,
    }),
    []
  );

  if (loading) {
    return (
      <div className="spinner-container">
        <img className="logo-init-container" src={logo} alt="Logo de Carga" />
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <DarkModeContext.Provider value={darkModeContextValue}>
      <DarkModeDispatchContext.Provider value={darkModeDispatchValue}>
        <div className={`page-container ${darkMode ? "dark-mode" : ""}`}>
          <div className="content-wrap">
            <DndProvider backend={HTML5Backend}>
              <BrowserRouter>
                <React.Suspense fallback={
                  <div className="spinner-container">
                    <img className="logo-init-container" src={logo} alt="Logo de Carga" />
                    <div className="spinner"></div>
                  </div>
                }>
                  <Routes>
                    <Route
                      index
                      element={
                        <ProtectedRoute currentUser={currentUser}>
                          <LazyGestionarTickets />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="login" element={<Login />} />
                    <Route path="signin" element={<SignInWithGoogle />} />
                    <Route
                      path="GestionarTickets"
                      element={
                        <ProtectedRoute currentUser={currentUser}>
                          <LazyGestionarTickets />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="dashboard"
                      element={
                        <ProtectedRoute currentUser={currentUser}>
                          <LazyHome />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="calendario"
                      element={
                        <ProtectedRoute currentUser={currentUser}>
                          <GestionCalendarios />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="areas"
                      element={
                        <ProtectedRoute currentUser={currentUser}>
                          <Areas />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="miPerfil"
                      element={
                        <ProtectedRoute currentUser={currentUser}>
                          <Perfil />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="CrearPerfil"
                      element={
                        <ProtectedRoute currentUser={currentUser}>
                          <CrearPerfil />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="archivados"
                      element={
                        <ProtectedRoute currentUser={currentUser}>
                          <Archivados />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="workSpaces"
                      element={
                        <ProtectedRoute currentUser={currentUser}>
                          <LazyWorkSpaces />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="selectArea"
                      element={
                        <ProtectedRoute currentUser={currentUser}>
                          <SelectArea />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="myprofile"
                      element={
                        <ProtectedRoute currentUser={currentUser}>
                          <MyProfile />
                        </ProtectedRoute>
                      }
                    />
    
                    <Route
                      path="stats"
                      element={
                        <ProtectedRoute currentUser={currentUser}>
                          <LazyStats />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="ticketResume"
                      element={
                        <ProtectedRoute currentUser={currentUser}>
                          <TicketResume />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="selectBusiness"
                      element={
                        <ProtectedRoute currentUser={currentUser}>
                          <SelectBusiness />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="registerUser" element={<RegisterUser />} />
                    <Route path="googleAuth" element={<GoogleAuth />} />
                    <Route path="registro" element={<Registro />} />
                    <Route path="image" element={<ImageUploader />} />
                    <Route
                      path="registrarusuarios"
                      element={<RegistroUsuarios />}
                    />
                    <Route path="politicas" element={<Politicas />} />
                    <Route path="condiciones" element={<Condiciones />} />

                    {/* Agregar nueva ruta pública para workspace */}
                    <Route 
                      path="/:ticketId" 
                      element={
                        <PublicWorkspace />
                      } 
                    />

                  </Routes>
                </React.Suspense>
              </BrowserRouter>
            </DndProvider>
          </div>
          <ToastContainer />
          <MemoizedFooter />
        </div>
      </DarkModeDispatchContext.Provider>
    </DarkModeContext.Provider>
  );
}

export default App;