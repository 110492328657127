import React, { useState, useContext, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AuthContext } from '../../context/AuthContext';
import { db } from '../firebase';
import { collection, query, getDocs } from 'firebase/firestore';
import Modal from 'react-modal';
import './PopUp.css';
import moment from 'moment-timezone';
import { createTicketNew } from '../Functions';
import { agregarEventoACalendario, sincronizarDesdeGoogleCalendar } from '../calendario/FunctionsCalendar';
import { 
  FileText, 
  Calendar, 
  Clock, 
  Tag, 
  Flag, 
  Check, 
  X,
  AlertCircle,
  RepeatIcon,
  Wand2,
  Hourglass,
  CheckCircle2
} from 'lucide-react';
import RecurrenceSelector from './RecurrenceSelector';
import { toast } from 'react-toastify';
import AttendeeSelector from './AttendeeSelector';
import { debounce } from 'lodash';

function ResumeTarea({ isOpen, onClose, onTaskCreated, type, selectedDateTime }) {


  const { currentUser, businessEmail, businessName, accessToken, refreshToken, tokenExpiry } = useContext(AuthContext);
  const tituloInputRef = useRef(null);

  const [titulo, setTitulo] = useState('');
  const [area, setArea] = useState('');
  const [areas, setAreas] = useState([]);
  const [prioridad, setPrioridad] = useState('Media');
  const [todoElDia, setTodoElDia] = useState(selectedDateTime?.isAllDay || false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loadingTasks, setLoadingTasks] = useState(false);

  const today = moment().tz("America/Santiago").format('YYYY-MM-DD');

  const [fechaCreacion] = useState(today);
  const [fechaVencimiento, setFechaVencimiento] = useState(
    selectedDateTime?.date || moment().format('YYYY-MM-DD')
  );
  const [horaInicio, setHoraInicio] = useState(() => {
    const horaSeleccionada = selectedDateTime?.time;
    return horaSeleccionada || moment().format('HH:00');
  });
  const [horaTermino, setHoraTermino] = useState(() => {
    if (selectedDateTime?.time) {
      const horaTerminoCalculada = moment(selectedDateTime.time, 'HH:mm')
        .add(1, 'hours')
        .format('HH:mm');
      return horaTerminoCalculada;
    }
    return '';
  });
  const [recurrenceRule, setRecurrenceRule] = useState('');

  const isDateTimePreset = !!selectedDateTime;
  const isHourPreset = !!selectedDateTime?.time;

  const [attendees, setAttendees] = useState([]);
  const [enableMeet, setEnableMeet] = useState(false);
  const [attendeeError, setAttendeeError] = useState('');

  const [selectedAreaColor, setSelectedAreaColor] = useState('');

  const [prioridadColor, setPrioridadColor] = useState('#FFA500');

  const prioridadColors = {
    alta: '#FF4444',
    media: '#FFA500',
    baja: '#4CAF50'
  };

  const [descripcion, setDescripcion] = useState('');

  const [isGeneratingDescription, setIsGeneratingDescription] = useState(false);

  const [isGeneratingSuggestions, setIsGeneratingSuggestions] = useState(false);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['clean']
    ]
  };

  const formats = [
    'bold', 'italic', 'underline',
    'list', 'bullet'
  ];

  const [titleTimeout, setTitleTimeout] = useState(null);

  const quillRef = useRef(null);

  const [showTitleCheck, setShowTitleCheck] = useState(false);

  const handleAreaChange = (e) => {
    const selectedAreaId = e.target.value;
    setArea(selectedAreaId);
    
    const selectedArea = areas.find(a => a.id === selectedAreaId);
    if (selectedArea) {
      setSelectedAreaColor(selectedArea.color);
    } else {
      setSelectedAreaColor('');
    }
  };

  const handlePrioridadChange = (e) => {
    const selectedPrioridad = e.target.value;
    setPrioridad(selectedPrioridad);
    setPrioridadColor(prioridadColors[selectedPrioridad.toLowerCase()]);
  };

  useEffect(() => {
    if (type === 'evento' && !todoElDia && horaInicio) {
      if (!selectedDateTime?.time) {
        const horaTerminoDefault = moment(horaInicio, 'HH:mm')
          .add(1, 'hours')
          .format('HH:mm');
        setHoraTermino(horaTerminoDefault);
      }
    }
  }, [horaInicio, type, todoElDia, selectedDateTime]);

  useEffect(() => {
    const fetchAreas = async () => {
      if (businessEmail) {
        const q = query(collection(db, "business", businessEmail, "areas"));
        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push({ ...doc.data(), id: doc.id });
        });
        setAreas(items);
      }
    };
    fetchAreas();
  }, [businessEmail]);

  useEffect(() => {
    if (isOpen) {
      const timeoutId = setTimeout(() => {
        if (tituloInputRef.current) {
          tituloInputRef.current.focus();
        }
      }, 300);

      return () => clearTimeout(timeoutId);
    }
  }, [isOpen]);

  useEffect(() => {
    // Actualizamos todoElDia cuando cambia selectedDateTime
    if (selectedDateTime?.isAllDay) {
      setTodoElDia(true);
    }
  }, [selectedDateTime]);

  const handleCreateTask = async () => {
    // Validación más clara
    const missingFields = [];
    if (!titulo) missingFields.push('título');
    if (!area) missingFields.push('área');

    if (missingFields.length > 0) {
      const errorMsg = `Para poder crear ${type === 'tarea' ? 'la tarea' : 'el evento'}, debes completar: ${missingFields.join(' y ')}`;
      setErrorMessage(errorMsg);
      toast.error(errorMsg);
      return;
    }

    setLoadingTasks(true);
    setSuccessMessage(`Agregando ${type}`);
    setErrorMessage('');

    try {
      const selectedArea = areas.find(a => a.id === area);
      if (!selectedArea) {
        throw new Error('El área seleccionada no es válida');
      }

      const color = selectedArea.color || "#000000";
      const icon = selectedArea.icon || "📌";
      const taskStatus = 'Proceso';

      const tituloConIcono = `${icon} ${titulo}`;
      const formattedFechaCreacion = fechaCreacion;
      const formattedFechaVencimiento = moment(fechaVencimiento).format('DD-MM-YYYY');

      let horaInicioFinal = todoElDia ? "Todo el día" : (horaInicio || "00:00");
      let horaTerminoFinal = todoElDia ? "Todo el día" : (horaTermino || moment(horaInicio || "00:00", "HH:mm").add(60, 'minutes').format("HH:mm"));

      // Crear ticket en Firebase
      const refDoc = await createTicketNew(
        tituloConIcono,
        businessName,
        prioridad,
        formattedFechaVencimiento,
        formattedFechaCreacion,
        area,
        taskStatus,
        currentUser.email,
        businessEmail,
        descripcion || '',
        descripcion || '',
        "No aplica",
        descripcion || '',
        currentUser.displayName,
        [currentUser.email, ...attendees],
        currentUser.email,
        formattedFechaCreacion,
        horaInicioFinal,
        horaTerminoFinal,
        type,
        color,
        recurrenceRule && recurrenceRule.trim() !== '',
        formattedFechaVencimiento
      );

      // Verificar que el documento se creó correctamente
      if (!refDoc || !refDoc.id) {
        throw new Error('Error al crear el documento en Firebase');
      }

      // Preparar fechas para Google Calendar - CORREGIDO
      const fechaInicioISO = todoElDia 
        ? moment.tz(fechaVencimiento, "America/Santiago").startOf('day').format('YYYY-MM-DD')
        : moment.tz(`${fechaVencimiento} ${horaInicioFinal}`, "YYYY-MM-DD HH:mm", "America/Santiago").format();
      
      const fechaVencimientoISO = todoElDia
        ? moment.tz(fechaVencimiento, "America/Santiago").endOf('day').format('YYYY-MM-DD')
        : moment.tz(`${fechaVencimiento} ${horaTerminoFinal}`, "YYYY-MM-DD HH:mm", "America/Santiago").format();

      // Sincronizar con Google Calendar
      await agregarEventoACalendario(
        businessEmail,
        refDoc.id,
        tituloConIcono,
        descripcion || '',
        fechaInicioISO,
        fechaVencimientoISO,
        todoElDia ? null : horaInicioFinal,
        todoElDia ? null : horaTerminoFinal,
        color,
        'primary',
        currentUser, 
        accessToken, 
        refreshToken, 
        tokenExpiry,
        null,
        recurrenceRule || '',
        attendees,
        enableMeet,
        area
      );

      // Manejar invitaciones por email
      if (type === 'evento' && attendees.length > 0) {
        try {
          const invitationPromises = attendees.map(async attendeeEmail => {
            const response = await fetch('/.netlify/functions/sendEventInvitation', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                invitedEmail: attendeeEmail,
                invitedName: '',
                creatorName: currentUser.displayName,
                eventTitle: titulo,
                eventDate: moment(fechaVencimiento).format('DD/MM/YYYY'),
                startTime: todoElDia ? null : horaInicioFinal,
                endTime: todoElDia ? null : horaTerminoFinal,
                isAllDay: todoElDia,
                recurrenceRule: recurrenceRule,
                description: descripcion
              }),
            });

            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(`Error ${response.status}: ${errorData.details || errorData.error || 'Error desconocido'}`);
            }

            return response;
          });

          await Promise.all(invitationPromises);
        } catch (error) {
          console.error('Error enviando invitaciones por email:', error);
          toast.warning(`El evento se creó pero hubo un problema al enviar algunas invitaciones: ${error.message}`);
        }
      }

      // Manejar eventos recurrentes - CORREGIDO
      if (recurrenceRule && recurrenceRule.trim() !== '') {
        await new Promise(resolve => setTimeout(resolve, 2000));
        
        await sincronizarDesdeGoogleCalendar(
          businessEmail,
          currentUser,
          accessToken,
          refreshToken,
          tokenExpiry,
          null
        );
        
        setLoadingTasks(false);
        onClose();
        if (onTaskCreated) onTaskCreated();
        toast.success('Evento recurrente creado y sincronizado exitosamente');
      } else {
        setLoadingTasks(false);
        onClose();
        if (onTaskCreated) onTaskCreated();
      }

    } catch (error) {
      console.error('Error creando el ticket:', error);
      const errorMsg = error.message || 'Error al crear la tarea o evento';
      setErrorMessage(errorMsg);
      toast.error(errorMsg);
      setLoadingTasks(false);
    }
  };

  const handleClickOutside = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const generateAutomaticDescription = async () => {
    if (!titulo) {
      toast.warning('Por favor, ingresa un título primero');
      return;
    }

    setIsGeneratingDescription(true);
    try {
      const response = await fetch('/.netlify/functions/generateTaskDescription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          titulo,
          area: areas.find(a => a.id === area)?.id
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || data.details || 'Error al generar la descripción');
      }

      if (!data.description) {
        throw new Error('No se recibió una descripción válida');
      }

      setDescripcion(data.description);
    } catch (error) {
      console.error('Error detallado:', error);
      toast.error(error.message || 'Error al generar la descripción automática');
    } finally {
      setIsGeneratingDescription(false);
    }
  };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitulo(newTitle);
    setShowTitleCheck(newTitle.trim().length > 0);
    
    // Restaurar la funcionalidad de sugerencias automáticas
    if (titleTimeout) {
      clearTimeout(titleTimeout);
    }
    
    if (newTitle.length >= 3) {
      const newTimeout = setTimeout(() => {
        generateSuggestions(newTitle);
      }, 2000);
      
      setTitleTimeout(newTimeout);
    }
  };

  const handleTitleBlur = () => {
    if (titulo.trim().length > 0) {
      setShowTitleCheck(true);
      
      // Generar sugerencias al perder el foco si el título tiene suficiente longitud
      if (titulo.length >= 3) {
        if (titleTimeout) {
          clearTimeout(titleTimeout);
        }
        generateSuggestions(titulo);
      }
    }
  };

  const handleTitleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (quillRef.current) {
        const editor = quillRef.current.getEditor();
        editor.focus();
      }
    }
  };

  const generateSuggestions = async (title) => {
    if (!title || title.length < 3) return;
    
    setIsGeneratingSuggestions(true);
    
    try {
      const response = await fetch('/.netlify/functions/generateTaskSuggestions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          titulo: title,
          availableAreas: areas.map(area => ({
            ...area,
            description: area.description || ''
          }))
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || data.details || 'Error al generar sugerencias');
      }

      if (data.area) {
        setArea(data.area);
        const selectedArea = areas.find(a => a.id === data.area);
        if (selectedArea) {
          setSelectedAreaColor(selectedArea.color);
        }
      }

      if (data.priority) {
        setPrioridad(data.priority);
        setPrioridadColor(prioridadColors[data.priority.toLowerCase()]);
      }

    } catch (error) {
      console.error('Error detallado:', error);
    } finally {
      setIsGeneratingSuggestions(false);
    }
  };

  useEffect(() => {
    return () => {
      if (titleTimeout) {
        clearTimeout(titleTimeout);
      }
    };
  }, [titleTimeout]);

  useEffect(() => {
    console.log('Estado de isGeneratingSuggestions:', isGeneratingSuggestions);
  }, [isGeneratingSuggestions]);

  return (
    <Modal
      overlayClassName="popupBackground"
      className="popupContainer"
      isOpen={isOpen}
      onRequestClose={onClose}
      onClick={handleClickOutside}
      shouldFocusAfterRender={false}
      shouldReturnFocusAfterClose={true}
    >
      <div className="popupHeader">
        <h3 className="popupTitle">
          <FileText size={20} style={{ marginRight: '10px' }}/> 
          Crear {type === 'tarea' ? 'Tarea' : `Evento el día ${moment(fechaVencimiento).format('DD-MM-YYYY')}`}
        </h3>
      </div>

      <div className="popupContent">
        <div className="edit-event-section">
          <div className="edit-form-content">
            {/* Columna izquierda: Título, Descripción, Invitados y Videollamada */}
            <div className="form-column">
              <div className="form-field">
                <label>
                  <FileText size={16} />
                  Título
                </label>
                <div className="title-input-container">
                  <input
                    type="text"
                    value={titulo}
                    onChange={handleTitleChange}
                    onBlur={handleTitleBlur}
                    onKeyPress={handleTitleKeyPress}
                    placeholder="Nombre de la tarea o evento"
                    ref={tituloInputRef}
                    className="animate-field"
                    autoFocus
                  />
                  <CheckCircle2 
                    size={18} 
                    className={`title-check ${showTitleCheck ? 'visible' : ''}`}
                  />
                </div>
              </div>

              <div className="form-field">
                <label className="description-label">
                  <div>
                    <FileText size={16} />
                    Descripción
                  </div>
                  <button
                    type="button"
                    onClick={generateAutomaticDescription}
                    className="magic-button"
                    disabled={isGeneratingDescription || !titulo}
                    title={isGeneratingDescription ? 'Generando descripción...' : 'Generar descripción automática'}
                  >
                    {isGeneratingDescription ? (
                      <div className="spinner"></div>
                    ) : (
                      <Wand2 size={16} />
                    )}
                  </button>
                </label>
                <div className="quill-container">
                  <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    value={descripcion}
                    onChange={setDescripcion}
                    modules={modules}
                    formats={formats}
                    placeholder="Agrega una descripción del evento..."
                    className="custom-quill"
                  />
                </div>
              </div>

              {/* Invitados y Videollamada */}
              {type === 'evento' && (
                <div className="form-field">
                  <AttendeeSelector
                    attendees={attendees}
                    setAttendees={setAttendees}
                    enableMeet={enableMeet}
                    setEnableMeet={setEnableMeet}
                    error={attendeeError}
                  />
                </div>
              )}
            </div>

            {/* Columna derecha: Horarios, Todo el día, Repetición, Área y Prioridad */}
            <div className="form-column">
              <div className="all-day-group">
                <div className="section-title">
                  <Hourglass size={16} />
                  <span>Duración</span>
                </div>

                <label className="all-day-toggle" htmlFor="todoElDia">
                  <span className="all-day-label">
                    <Clock size={16} />
                    ¿Evento todo el día?
                  </span>
                  <input
                    id="todoElDia"
                    className="all-day-checkbox"
                    type="checkbox"
                    checked={todoElDia}
                    onChange={(e) => setTodoElDia(e.target.checked)}
                  />
                  <div className="toggle-slider"></div>
                </label>

                {!todoElDia && (
                  <div className="time-fields-container">
                    <div className="form-field">
                      <label>
                        <Clock size={16} />
                        Inicia
                      </label>
                      <input
                        type="time"
                        value={horaInicio}
                        onChange={(e) => setHoraInicio(e.target.value)}
                        required={!todoElDia}
                        step="900"
                      />
                    </div>

                    {type === 'evento' && (
                      <div className="form-field">
                        <label>
                          <Clock size={16} />
                          Termina
                        </label>
                        <input
                          type="time"
                          value={horaTermino}
                          onChange={(e) => setHoraTermino(e.target.value)}
                          required={!todoElDia}
                          step="900"
                        />
                      </div>
                    )}
                  </div>
                )}

                {/* Selector de Recurrencia */}
                {type === 'evento' && (
                  <div className="form-field">
                    <label>
                      <RepeatIcon size={16} />
                      Repetición
                    </label>
                    <RecurrenceSelector
                      value={recurrenceRule}
                      onChange={setRecurrenceRule}
                    />
                  </div>
                )}
              </div>
              
              <div className="form-row">
                <div className="form-field flex-1">
                  <label className="label-with-spinner-23f4">
                    <div className="label">
                      <Tag size={16} />
                      <span> Área</span>
                    </div>
                    {isGeneratingSuggestions && (
                      <div className="spinner-23f4" />
                    )}
                  </label>
                  <select
                    value={area}
                    onChange={handleAreaChange}
                    style={{
                      backgroundColor: selectedAreaColor ? `${selectedAreaColor}40` : '',
                      transition: 'background-color 0.3s ease'
                    }}
                  >
                    <option value="">Seleccionar área</option>
                    {areas.map((area, index) => (
                      <option 
                        key={index} 
                        value={area.id}
                        data-icon={area.icon}
                        style={{
                          backgroundColor: `${area.color}40`
                        }}
                      >
                        {area.icon} {area.id}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-field flex-1">
                  <label className="label-with-spinner-23f4">
                    <div className="label">
                      <Flag size={16} />
                      <span> Prioridad</span>
                    </div>
                    {isGeneratingSuggestions && (
                      <div className="spinner-23f4" />
                    )}
                  </label>
                  <select
                    value={prioridad}
                    onChange={handlePrioridadChange}
                    style={{
                      backgroundColor: `${prioridadColor}40`,
                      transition: 'background-color 0.3s ease'
                    }}
                  >
                    <option value="Alta">Alta</option>
                    <option value="Media">Media</option>
                    <option value="Baja">Baja</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          {errorMessage && (
            <div className="errorMessageResumeTarea">
              <span>
                Para poder crear {type === 'tarea' ? 'la tarea' : 'el evento'}, debes completar:
                {!titulo && ' • Título'}
                {!area && ' • Área'}
              </span>
            </div>
          )}

          {successMessage && (
            <div className="success-message-resumeTarea">
              {successMessage}
              {loadingTasks && (
                <div className="spinner-container-resumeTarea">
                  <div className="spinner-ring-resumeTarea"></div>
                  <div className="spinner-dot-resumeTarea"></div>
                </div>
              )}
            </div>
          )}

          <div className="form-actions">
            <button 
              onClick={handleCreateTask} 
              className="save-edittask-button" 
              disabled={loadingTasks}
            >
              <Check size={18} />
              Crear {type === 'tarea' ? 'Tarea' : 'Evento'}
            </button>
            <button onClick={onClose} className="cancel-button">
              <X size={18} />
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ResumeTarea;