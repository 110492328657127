import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { addDoc, collection, onSnapshot, Timestamp } from "firebase/firestore";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import DOMPurify from "dompurify";
import { createNotification } from "../Functions"; // Importar createNotification
import "./ChatWorkspace.css";

function ChatWorkspace({
  emailBusiness,
  ticketId,
  title,
  users = [],
  department,
  onClose,
  workspaceData,
}) {
  // 1. Todos los hooks de estado
  const [text, setText] = useState("");
  const { currentUser } = useContext(AuthContext);
  const [chats, setChats] = useState([]);
  const chatContainerRef = useRef(null);
  const chatRef = useRef(null);
  const [loading, setLoading] = useState(false);

  // 2. Constantes que no dependen de hooks
  const defaultImageURL = "https://firebasestorage.googleapis.com/v0/b/chat-e4572.appspot.com/o/avatar.jpg?alt=media&token=41dc2376-4dfe-4a09-9000-b6fc8f865698";
  const date = Timestamp.now();
  const safeUsers = Array.isArray(users) ? users : [];

  // 3. Todos los hooks de efecto
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chatRef.current && !chatRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    if (!emailBusiness || !ticketId) return;

    setLoading(true);
    const q = collection(
      db,
      "business",
      emailBusiness,
      "tickets",
      ticketId,
      "messages"
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      items.sort((a, b) => a.date.seconds - b.date.seconds);
      setChats(items);
      setLoading(false);
    });
    return unsubscribe;
  }, [emailBusiness, ticketId]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chats]);

  // 4. Funciones de manejo
  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const sanitizeHTML = (html) => {
    return DOMPurify.sanitize(html);
  };

  const handleSend = useCallback(async () => {
    if (!emailBusiness || !ticketId || !text.trim()) return;

    try {
      await addDoc(
        collection(
          db,
          "business",
          emailBusiness,
          "tickets",
          ticketId,
          "messages"
        ),
        {
          name: currentUser.displayName,
          photo: currentUser.photoURL,
          uid: currentUser.uid,
          date: date,
          message: text,
        }
      );

      // Crear notificación para los usuarios
      safeUsers.forEach((userEmail) => {
        if (userEmail !== currentUser.email) {
          const filteredWorkspaceData = Object.fromEntries(
            Object.entries(workspaceData).filter(([_, v]) => v !== undefined)
          );
          const titleNotification =
            "Nuevo mensaje de " +
            currentUser.displayName +
            " en la tarea " +
            title;
          createNotification(
            userEmail,
            titleNotification,
            text,
            filteredWorkspaceData,
            "chatMessage",
            department,
            emailBusiness
          );
        }
      });

      setText("");
    } catch (e) {
      console.error("Error sending message: ", e);
    }
  }, [text, emailBusiness, ticketId, currentUser, date, safeUsers, workspaceData, title, department]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  // 5. Validación y return temprano
  if (!emailBusiness || !ticketId) {
    console.warn('Missing required props in ChatWorkspace');
    return null;
  }

  // 6. Return del componente
  return (
    <div className="chat-workspace" ref={chatRef}>
      <div className="chat-header">
        <h3>Chat</h3>
      </div>
      <div ref={chatContainerRef} className="chat-content">
        {loading && <p>Cargando mensajes...</p>}
        {chats.map((chat, index) => (
          <div
            key={index}
            className={`chat-message ${
              chat.uid === currentUser.uid
                ? "chat-message-sender"
                : "chat-message-receiver"
            }`}
          >
            <img
              src={chat.photo || defaultImageURL}
              alt="profile"
              className="chat-profile-pic"
            />
            <div className="chat-message-content">
              <strong>{chat.name}:</strong>
              <div
                className="chat-message-text"
                dangerouslySetInnerHTML={{ __html: sanitizeHTML(chat.message) }}
              ></div>
              <div className="chat-message-time">
                {chat.date.toDate().toLocaleString()}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={text}
          onChange={handleTextChange}
          onKeyPress={handleKeyPress}
          placeholder="Escribe tu mensaje"
          className="input-text"
        />
        <button className="send-button" onClick={handleSend}>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
}

export default ChatWorkspace;
